@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';
@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.multipleReportsModal {
  max-width: unset;
  width: 100%;
  height: 100%;
  margin: 16px;
  box-sizing: border-box;

  .container {
    display: grid;
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(0, 1fr) 42px;
    gap: 19px;
    padding: 36px 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }

  .scrollableContainer {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    .scrollableSelect {
      height: fit-content;
    }

    .scrollableReport {
      height: unset;

      &.isLoading {
        height: 100%;
        > div {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .multipleReportsModal {
    margin: 24px;
  }

  .container {
    gap: 24px;
  }
}

@media screen and (min-width: $sf-media-md-width) {
  .multipleReportsModal {
    margin: 24px;
    max-width: unset;

    .scrollableContainer {
      display: grid;
      grid-template-rows: 100%;
      grid-template-columns: auto minmax(0, 1fr);
      overflow: hidden;

      .scrollableSelect {
        height: 100%;
        width: 334px;
        border-right: 2px solid $sf-grey-100;
      }

      .scrollableReport {
        height: 100%;
      }
    }
  }
}

@media screen and (min-width: $sf-media-lg-width) {
  .multipleReportsModal {
    max-width: 1022px;

    .scrollableContainer {
      .scrollableSelect {
        width: 380px;
      }
    }
  }
}

@media screen and (min-width: $sf-media-xl-width) {
  .multipleReportsModal {
    max-width: 1290px;

    .scrollableContainer {
      .scrollableSelect {
        width: 526px;
      }
    }
  }
}

@media screen and (min-width: $sf-media-xxl-width) {
  .multipleReportsModal {
    max-width: 1240px;

    .scrollableContainer {
      .scrollableSelect {
        width: 316px;
      }
    }
  }
}

:global(.nightMode) {
  .multipleReportsModal {
    .scrollableContainer {
      .scrollableSelect {
        border-right-color: $sf-grey-700;
      }
    }
  }
}

@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';
@import '../../../Mixins/DialogCommon.module.scss';

.saveObjectDialog {
  [class*='MuiDialog-paper'] {
    @include responsiveDialogContent();

    height: 100%;
    margin: 24px;
    padding: 4px;
    overflow: unset;
    display: grid;
    grid-template-rows: 50px 1fr 66px;
    grid-template-columns: 100%;
    transition-timing-function: cubic-bezier(0.3, 0, 0.2, 1) !important;
  }

  .content {
    min-height: 0;

    .scrollableContainer {
      padding: 0 16px;
      box-sizing: border-box;
    }
  }

  footer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    padding: 16px;

    .discardBtn {
      display: none;
    }

    .saveBtn {
      width: 100%;
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .saveObjectDialog {
    .content {
      .scrollableContainer {
        padding: 0 36px;
      }
    }

    footer {
      .discardBtn {
        display: flex;
      }

      .saveBtn {
        width: auto;
      }
    }
  }
}

.itemForm {
  display: grid;
  gap: 24px;
  margin-top: 12px;

  .section {
    h2 {
      margin: 0 0 24px 0;
      line-height: 28px;
      font-weight: 500;
    }
  }
}

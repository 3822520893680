@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.filterChipList {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .chipList {
    display: flex;
    gap: 12px;

    .chip {
      cursor: pointer;

      &.chipSelected {
        background-color: rgba($sf-blue-100, 0.6) !important;
        border-color: $sf-blue-800 !important;
        color: $sf-blue-800 !important;
      }
    }
  }
}

:global(.nightMode) {
  .filterChipList {
    .chipList {
      .chip {
        &.chipSelected {
          background-color: rgba($sf-blue-200, 0.1) !important;
          border-color: $sf-blue-400 !important;
          color: $sf-blue-400 !important;
        }
      }
    }
  }
}

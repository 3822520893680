@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.reportBody {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 36px 36px 0 36px;
  box-sizing: border-box;

  .title {
    margin: 0;
  }
}

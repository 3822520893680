@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';
@import '../ReportWizardView.module.scss';

.contactTypeView {
  @include wizardView();

  .content {
    .buttonsContainer {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .contactTypeView {
    .content {
      .buttonsContainer {
        flex-direction: row;
      }
    }
  }
}

:global(.nightMode) {
  .contactTypeView {
    @include wizardView(false);
  }
}

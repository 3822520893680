@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.userInvitations {
  background-color: #fafafa;

  .scrollableContainer {
    padding: 16px;
    box-sizing: border-box;
    display: grid;
    align-items: center;
    justify-content: center;

    .card {
      height: auto;
      padding: 40px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: 60px;

      .header {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 12px;

        > h2 {
          margin: 0;
        }
      }

      .spinner {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px 0;
      }
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .userInvitations {
    .scrollableContainer {
      padding: 24px 117px;
    }
  }
}

@media screen and (min-width: $sf-media-md-width) {
  .userInvitations {
    .scrollableContainer {
      padding: 24px 208px;
    }
  }
}

@media screen and (min-width: $sf-media-lg-width) {
  .userInvitations {
    .scrollableContainer {
      padding: 0 390px 48px;
    }
  }
}

@media screen and (min-width: $sf-media-xl-width) {
  .userInvitations {
    .scrollableContainer {
      padding: 0 549px 48px;
    }
  }
}

@media screen and (min-width: $sf-media-xxl-width) {
  .userInvitations {
    .scrollableContainer {
      padding: 0 704px 48px;
    }
  }
}

:global(.nightMode) {
  .userInvitations {
    background-color: $sf-grey-900;
  }
}

@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.myWidgetHeader {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 12px;

  > h2 {
    margin: 0;
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .myWidgetHeader {
    flex-direction: row;
    align-items: center;
    gap: 24px;
  }
}

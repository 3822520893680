@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.roleCard {
  cursor: pointer;

  .card {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .roleInfo {
      h3,
      p {
        margin: 0;
      }

      h3 {
        margin-bottom: 7px;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        color: $sf-grey-600;
      }
    }
  }
}

:global(.nightMode) {
  .roleCard {
    p {
      color: $sf-grey-400;
    }
  }
}

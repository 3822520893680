@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';
@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.pendingReportsMenu {
  // Fix safari outline on focus
  *:focus {
    outline-style: none;
  }

  [class*='MuiMenu-paper'] {
    width: 277px;
  }

  .noReportsMsg {
    margin: 0;
    padding: 19px 24px;
    font-size: 16px;
    line-height: 24px;
  }

  .menu {
    header {
      padding: 12px 24px;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.02em;
      color: $sf-grey-600;
    }

    .itemList {
      padding-bottom: 6px;

      .item {
        padding: 6px 24px;
        display: flex;
        gap: 15px;
        height: auto;

        &:hover,
        &:active {
          background-color: transparent;
          cursor: default;
        }

        .icon {
          min-width: 24px;
        }

        .itemText {
          width: 190px;

          .itemTitle {
            margin: 0;
            font-size: 16px;
            line-height: 24px;
            color: $sf-grey-900;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .itemSubtitle {
            margin: 0;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.02em;
            color: $sf-grey-600;
          }
        }
      }
    }

    footer {
      padding: 12px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .footerMsg {
        font-size: 12px;
        line-height: 14px;
        color: $sf-grey-900;

        strong {
          font-weight: 900;
        }
      }
    }
  }
}

:global(.nightMode) {
  .pendingReportsMenu {
    .menu {
      header {
        color: $sf-grey-400;
      }

      .itemList {
        .item {
          .itemText {
            .itemTitle {
              color: $sf-grey-50;
            }

            .itemSubtitle {
              color: $sf-grey-400;
            }
          }
        }
      }

      footer {
        .footerMsg {
          color: $sf-grey-300;
        }
      }
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .pendingReportsMenu {
    [class*='MuiMenu-paper'] {
      width: 292px;
    }

    .menu {
      .itemList {
        .item {
          .itemText {
            width: 205px;
          }
        }
      }
    }
  }
}

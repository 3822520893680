@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.scrollable {
  background-color: $sf-background-light;
}

:global(.nightMode) {
  .scrollable {
    background-color: $sf-background-dark;
  }
}

.planUpgradeWizard {
  height: 100%;
  box-sizing: border-box;

  .wizardCard {
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 100%;
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .planUpgradeWizard {
    padding: 70px 24px 94px;
  }
}

@media screen and (min-width: $sf-media-md-width) {
  .planUpgradeWizard {
    padding: 24px 191px;
  }
}

@media screen and (min-width: $sf-media-lg-width) {
  .planUpgradeWizard {
    padding: 0 338px 48px;
  }
}

@media screen and (min-width: $sf-media-xl-width) {
  .planUpgradeWizard {
    padding: 0 418px 48px;
  }
}

@media screen and (min-width: $sf-media-xxl-width) {
  .planUpgradeWizard {
    padding: 75px 656px 122px;
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';
@import '~sfui/dist/Styles/SFTypography/SFTypography.module.scss';

.analyticsRequestTimeout {
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    .icon {
      path {
        fill: $sf-blue-500 !important;
      }
    }

    .title,
    .subtitle {
      max-width: 480px;
      margin: 0;
      text-align: center;
    }
  }
}

:global(.nightMode) {
  .analyticsRequestTimeout {
    .content {
      .icon {
        path {
          fill: $sf-blue-200 !important;
        }
      }
    }
  }
}

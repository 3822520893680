@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';
@import '../LandingCommon.module.scss';

.landingPresentation {
  @include LandingComponent;
  padding-top: 48px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 45px;

  .content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .brand {
      width: 166px;
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .title {
        white-space: pre-line;
        margin: 0;
        font-size: 44px;
        line-height: 52px;
        font-weight: 900;
      }

      .description {
        margin: 0;
        font-size: 18px;
        line-height: 26px;
      }
    }

    .userButtons {
      display: flex;
      gap: 14px;
    }
  }

  .image {
    height: 314px;
    position: relative;

    > img {
      height: 100%;
      position: absolute;
      margin-left: -5%;
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .landingPresentation {
    gap: 40px;

    .content {
      .brand {
        width: 160px;
      }
    }

    .image {
      height: 642px;
    }
  }
}

@media screen and (min-width: $sf-media-md-width) {
  .landingPresentation {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
    gap: 24px;
    padding-top: 24px;

    .image,
    .image > img {
      height: 568px;
      margin-left: -5%;
    }
  }
}

@media screen and (min-width: $sf-media-lg-width) {
  .landingPresentation {
    .content {
      .brand {
        width: 185px;
      }

      .info {
        .title {
          font-size: 54px;
          line-height: 63px;
        }
      }
    }

    .image,
    .image > img {
      height: 558px;
    }
  }
}

@media screen and (min-width: $sf-media-xl-width) {
  .landingPresentation {
    .content {
      gap: 60px;

      .brand {
        width: 236px;
      }

      .info {
        .title {
          font-size: 60px;
          line-height: 70px;
        }
      }
    }

    .image,
    .image > img {
      height: 702px;
    }
  }
}

@media screen and (min-width: $sf-media-xxl-width) {
  .landingPresentation {
    .image,
    .image > img {
      height: 860px;
      margin-left: -5%;
    }
  }
}

:global(.nightMode) {
  .landingPresentation {
    .content {
      .brand {
        filter: invert(100%) sepia(44%) saturate(28%) hue-rotate(15deg)
          brightness(109%) contrast(90%);

        path {
          fill: $sf-surface-light !important;
        }
      }
    }
  }
}

@import '../ReportWizardView.module.scss';

.contactFormView {
  @include wizardView();

  .content {
    .form {
      display: flex;
      flex-direction: column;
      gap: 36px;

      .citizenSection {
        margin: 15px 0 36px 0;
      }
    }

    .useOfForce {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .useOfForceSwitch {
        > h3 {
          font-weight: 500;
        }
      }
    }
  }
}

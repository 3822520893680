.clusterMap {
  position: relative;
  width: 100%;
  height: 100%;
}

:global(.SFAnalytics-ShareImage) {
  .clusterMap {
    height: auto;

    [class*='GoogleMap'] {
      height: 505px;
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

@mixin infoList() {
  display: flex;
  flex-direction: column;
  gap: 12px;

  > h4 {
    margin: 0;
  }

  > .h4 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    margin: 0;
  }

  .infoItem {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.rankingCardBody {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr) auto;
  grid-auto-rows: max-content;
  padding-right: 12px;
  box-sizing: border-box;

  .order {
    color: $sf-blue-main-light;
  }

  > * {
    padding: 18px 0;
    border-bottom: 1px solid $sf-grey-100;

    &.lastRowItem {
      border-bottom: 0;
    }
  }
}

:global(.nightMode) {
  .rankingCardBody {
    .order {
      color: $sf-blue-main-dark;
    }

    > * {
      border-color: $sf-grey-700;
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.linkContainer {
  display: flex;
  width: 100%;

  .link {
    cursor: pointer;
    display: flex;
    flex: 1;
    align-items: center;
    border-radius: 0px 2px 2px 0px;
    color: $sf-grey-600;
    text-decoration: none;
    line-height: 24px;
    font-weight: 400;
    font-size: 16px;
    padding-left: 24px;
    height: 48px;
    width: 100%;

    svg {
      path {
        fill: $sf-grey-400 !important;
      }
    }

    //Avoid hover on mobile when touched
    @media (hover: hover) {
      &.link:hover {
        background-color: rgba($sf-grey-a100, 0.3);
      }
    }

    &.link:active {
      background-color: rgba($sf-grey-a100, 0.5);
    }

    &.isSelected {
      color: $sf-blue-600;
      font-weight: 500;
      padding-left: 18px;

      svg {
        path {
          fill: $sf-blue-500 !important;
        }
      }
    }

    .text,
    .chip {
      margin-left: 12px;
      display: flex;
    }
  }

  .linkSelected {
    width: 6px;
    border-radius: 0px 2px 2px 0px;
    display: none;

    &.selected {
      background-color: $sf-blue-500;
      display: block;
    }
  }

  &.disabled {
    .link {
      color: $sf-grey-400;

      &:active,
      &:hover {
        background-color: unset !important;
        cursor: unset;
      }

      svg {
        path {
          fill: $sf-grey-200 !important;
        }
      }

      @media (hover: hover) {
        &:hover {
          background-color: unset;
        }
      }
    }

    .linkSelected,
    .linkSelected.selected {
      background-color: unset;
    }
  }
}

:global(.nightMode) {
  .linkContainer {
    .link {
      color: $sf-grey-400;

      //Avoid hover on mobile when touched
      @media (hover: hover) {
        &.link:hover {
          background-color: rgba($sf-grey-500, 0.3);
        }
      }

      &.link:active {
        background-color: rgba($sf-grey-500, 0.5);
      }

      &.isSelected {
        color: $sf-blue-200;

        svg {
          path {
            fill: $sf-blue-300 !important;
          }
        }
      }
    }

    .selected {
      background-color: $sf-blue-200;
    }

    &.disabled {
      .link {
        color: $sf-grey-600;

        svg {
          path {
            fill: $sf-grey-700 !important;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $sf-media-lg-width) {
  .linkContainer {
    .link {
      font-size: 14px;
      line-height: 20px;
      height: 44px;

      .chip {
        margin-left: auto;
      }
    }
  }
}

@media screen and (min-width: $sf-media-xl-width) {
  .linkContainer {
    .link {
      .chip {
        margin-left: 12px;
      }
    }
  }
}

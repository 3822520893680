@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFTypography/SFTypography.module.scss';

.itemListField {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .listLabel {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .requiredMsg {
    color: $sf-grey-600;
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .itemListFieldContent {
    padding-left: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .header {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }

    .separator {
      border-bottom: 1px solid $sf-grey-100;
    }
  }

  .itemListField {
    .addButton {
      margin-bottom: 12px;
    }
  }

  .helperText {
    @include component-messages;
    margin: 0;
    color: rgba(0, 0, 0, 0.54);

    &.error {
      color: $sf-red-main-light;
    }
  }
}

:global(.nightMode) {
  .itemListField {
    .requiredMsg {
      color: $sf-grey-400;
    }

    .itemListFieldContent {
      .separator {
        border-bottom: 1px solid $sf-grey-700;
      }
    }
  }

  .itemListField {
    .helperText {
      color: rgba(255, 255, 255, 0.7);

      &.error {
        color: $sf-red-main-dark;
      }
    }
  }
}

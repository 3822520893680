@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.completeYourInformationView {
  display: flex;
  flex-direction: column;
  gap: 48px;

  .content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .form {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .checkPolicies {
        label {
          gap: 0;
          color: $sf-grey-400;

          span {
            font-size: 14px;
            line-height: 20px;
          }

          span:nth-child(2) {
            padding-top: 11px;
          }
        }
      }
    }
  }
}

:global(.nightMode) {
  .completeYourInformationView {
    .checkPolicies {
      label {
        color: $sf-grey-600;
      }
    }
  }
}

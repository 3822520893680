@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.sFNavContentBrand {
  padding: 18px 24px 16px 24px;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.02em;
  text-align: left;

  .sfBadge {
    width: 20px;
    height: 20px;

    path {
      fill: $sf-grey-600 !important;
    }
  }

  .version {
    color: $sf-grey-600;
    margin: 0 0 4px;
  }

  a {
    color: $sf-grey-600;
    text-decoration: none;

    &.isOffline {
      pointer-events: none;
    }

    .semibold {
      font-weight: 500;
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .sFNavContentBrand {
    padding: 18px 24px 24px 24px;
  }
}

:global(.nightMode) {
  .sFNavContentBrand {
    .sfBadge {
      path {
        fill: $sf-grey-400 !important;
      }
    }

    .version,
    a {
      color: $sf-grey-400;
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

@mixin AuthView($lightTheme: true) {
  display: grid;
  gap: 24px;

  .title {
    margin: 0;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
  }

  .content,
  form {
    display: grid;
    gap: 12px;
  }

  .msgAccount {
    display: flex;
    justify-content: center;
    align-items: baseline;

    > span {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: center;
      margin-right: 18px;

      @if $lightTheme {
        color: $sf-grey-600;
      } @else {
        color: $sf-grey-400;
      }
    }
  }

  @media screen and (min-width: $sf-media-sm-width) {
    .title {
      padding-bottom: 24px;
    }
  }

  @media screen and (min-width: $sf-media-md-width) {
    .title {
      padding-bottom: 0px;
    }
  }

  @media screen and (min-width: $sf-media-xl-width) {
    .title {
      padding-bottom: 24px;
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.invitationListItem {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .avatar {
    width: 80px;
    min-width: 80px;
    height: 80px;
    min-height: 80px;
    box-sizing: border-box;
    border: 1px solid $sf-grey-100;
    background-color: $sf-surface-light;
    color: $sf-blue-400;
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .invitationListItem {
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    gap: 29px;

    .info {
      button {
        width: fit-content;
      }
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.scrollable {
  background-color: $sf-background-light;
}

:global(.nightMode) {
  .scrollable {
    background-color: $sf-background-dark;
  }
}

.onboardingWizard {
  height: 100%;

  &.hide {
    display: none;
  }

  .finish {
    padding: 16px;
    box-sizing: border-box;
  }

  .wizardCard {
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 100%;
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .onboardingWizard {
    padding: 70px 24px 94px;
    box-sizing: border-box;

    .finish {
      padding: 36px;
    }
  }
}

@media screen and (min-width: $sf-media-md-width) {
  .onboardingWizard {
    padding: 84px;
    padding: 12px 191px 24px;
    box-sizing: border-box;
  }
}

@media screen and (min-width: $sf-media-lg-width) {
  .onboardingWizard {
    padding: 0 338px 36px;
  }
}

@media screen and (min-width: $sf-media-xl-width) {
  .onboardingWizard {
    padding: 0 318px 48px;
  }
}

@media screen and (min-width: $sf-media-xxl-width) {
  .onboardingWizard {
    padding: 75px 556px 122px;
  }
}

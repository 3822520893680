@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';
@import '../LandingCommon.module.scss';

.landingHowTo {
  @include LandingComponent;
  padding-top: 90px;
  padding-bottom: 90px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .sectionName {
    padding-left: 8px;
  }

  .videos {
    display: flex;
    flex-direction: column;
    gap: 32px;

    > div {
      border-radius: 14px;
    }
  }

  > button {
    align-self: center;
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .landingHowTo {
    .videos {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}

.landingFeatureListItem {
  list-style: none;
  display: flex;
  gap: 9px;

  div {
    margin-top: 4px;
  }

  .text {
    margin: 0;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
  }
}

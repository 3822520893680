@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.noReportsMatches {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  padding-top: 80px;
  box-sizing: border-box;

  .icon {
    padding-bottom: 12px;
  }

  > h2 {
    margin: 0;
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .noReportsMatches {
    padding-top: 220px;
  }
}
@media screen and (min-width: $sf-media-md-width) {
  .noReportsMatches {
    padding-top: 120px;
  }
}
@media screen and (min-width: $sf-media-lg-width) {
  .noReportsMatches {
    padding-top: 160px;
  }
}
@media screen and (min-width: $sf-media-xl-width) {
  .noReportsMatches {
    padding-top: 220px;
  }
}
@media screen and (min-width: $sf-media-xxl-width) {
  .noReportsMatches {
    padding-top: 300px;
  }
}

:global(.nightMode) {
  .noReportsMatches {
    .icon > path {
      fill: $sf-blue-main-dark !important;
    }
  }
}

@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.reportHistoryPanel {
  height: 100%;
  padding: 0 16px 16px 16px;
  box-sizing: border-box;
  overflow: unset;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);

  > header {
    display: flex;
    justify-content: flex-end;

    button {
      margin-top: 8px;
    }
  }

  > footer {
    display: none;
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .reportHistoryPanel {
    padding: 36px;
    width: 396px;
    grid-template-rows: minmax(0, 1fr) auto;

    > header {
      display: none;
    }

    > footer {
      display: flex;
      justify-content: flex-end;
      padding-top: 36px;
      box-sizing: border-box;
    }
  }
}

@media screen and (min-width: $sf-media-md-width) {
  .reportHistoryPanel {
    width: 357px;
  }
}

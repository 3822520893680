@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.landingSectionName {
  margin: 0;
  font-size: 20px;
  line-height: 23px;
  font-weight: 700;
  color: $sf-blue-300;
  letter-spacing: -0.01em;
}

:global(.nightMode) {
  .landingSectionName {
    color: $sf-blue-50;
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '../../Mixins/AuthCommon.module.scss';

.verifyView {
  @include AuthView;

  p {
    margin: 0;
    color: $sf-grey-600;
  }

  .text {
    font-size: 16px;
    line-height: 24px;
  }

  .textSmall {
    font-size: 14px;
    line-height: 20px;
  }
}

:global(.nightMode) {
  .verifyView {
    @include AuthView(false);

    p {
      color: $sf-grey-400;
    }
  }
}

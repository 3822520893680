@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.reportCardHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 14px;

  .incidentNumber {
    display: flex;
    flex-direction: column;
    gap: 3px;
    word-break: break-all;
  }
}

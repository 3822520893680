@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.brand {
  cursor: pointer;
  height: 34px;
  width: 204px;
  box-sizing: border-box;

  > svg {
    height: 100%;
    width: 100%;

    path {
      fill: $sf-blue-900;
    }
  }
}

:global(.nightMode) {
  .brand {
    svg path {
      fill: $sf-grey-100 !important;
    }
  }
}

@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.reportsFilterModal {
  .actions {
    display: none;
  }

  div.dialogContainer {
    padding-top: 24px;
    grid-template-rows: minmax(0, 1fr);
  }

  div.dialogHeader {
    padding: 16px 24px 0;
  }

  &.showAdvanced {
    .drawerContent {
      padding-right: 0;

      > div {
        overflow: unset;
      }
    }

    div.dialogContent {
      padding-right: 0;

      > div {
        overflow: unset;
        display: grid;
        grid-template-rows: minmax(0, 1fr);
        gap: 24px;
      }
    }

    div.dialogPaper {
      max-width: unset;
      box-sizing: border-box;
      height: 100%;
    }

    div.dialogContainer {
      height: 100%;
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .reportsFilterModal {
    div.dialogPaper {
      width: 534px;
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      gap: 8px;
      padding-top: 24px;
      box-sizing: border-box;
      padding: 0 36px;
    }
  }
}

@media screen and (min-width: $sf-media-md-width) {
  .reportsFilterModal {
    div.dialogPaper {
      width: 642px;
    }

    &.showAdvanced {
      div.dialogPaper {
        width: 809px;
      }
    }
  }
}

@media screen and (min-width: $sf-media-lg-width) {
  .reportsFilterModal {
    div.dialogPaper {
      width: 604px;
    }

    &.showAdvanced {
      div.dialogPaper {
        width: 813px;
      }
    }
  }
}

@media screen and (min-width: $sf-media-xl-width) {
  .reportsFilterModal {
    div.dialogPaper {
      width: 765px;
    }

    &.showAdvanced {
      div.dialogPaper {
        width: 1026px;
      }
    }
  }
}

@media screen and (min-width: $sf-media-xl-width) {
  .reportsFilterModal {
    div.dialogPaper {
      width: 608px;
    }

    &.showAdvanced {
      div.dialogPaper {
        width: 1240px;
      }
    }
  }
}

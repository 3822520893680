@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFTypography/SFTypography.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.planDescriptionCard {
  box-sizing: border-box;
  padding: 24px 0 24px 24px;
  display: grid;
  grid-template-rows: auto auto minmax(0, 1fr) auto;
  background-color: $sf-surface-light;
  border: 1px solid $sf-grey-200;
  border-radius: 2px;

  .planName {
    display: flex;
    gap: 8px;
    align-items: center;
    padding-bottom: 12px;
    padding-right: 24px;

    > h6 {
      margin: 0;
      @include component-1-medium;
    }
  }

  .price {
    padding-right: 24px;

    > h3 {
      margin: 0;
    }

    > span {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: $sf-grey-600;
    }
  }

  .items {
    padding: 24px 0;
    box-sizing: border-box;
  }

  .button {
    padding-right: 24px;

    button {
      padding: 8px 0 !important;
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .planDescriptionCard {
    height: 100%;
  }
}

:global(.nightMode) {
  .planDescriptionCard {
    background-color: $sf-surface-dark;
    border-color: $sf-grey-700;

    .price {
      > span {
        color: $sf-grey-400;
      }
    }
  }
}

@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';
@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.reportCard {
  padding: 24px;
  box-sizing: border-box;
  min-height: 300px;

  .reportCardContainer {
    .leftMap {
      display: none;
    }

    .reportCardContent {
      .reportData {
        display: flex;
        flex-direction: column;
        gap: 24px;
        overflow: unset;
      }
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .reportCard {
    padding: 36px 0 36px 36px;

    .reportCardContainer {
      display: grid;
      grid-template-rows: 100%;
      max-height: 1068px;

      .reportCardContent {
        display: grid;
        grid-template-rows: auto minmax(0, 1fr);
        gap: 24px;

        .header {
          padding-right: 36px;
        }

        .reportData {
          padding-right: 36px;
          overflow: auto;

          .topMap {
            min-height: 250px;
            height: 250px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $sf-media-lg-width) {
  .reportCard {
    .reportCardContainer {
      grid-template-columns: minmax(0, 1fr) 336px;
      max-height: 1098px;

      .reportHistory {
        border-left: 2px solid $sf-grey-100;
        padding: 0 0 0 36px;
      }
    }
  }
}

@media screen and (min-width: $sf-media-xl-width) {
  .reportCard {
    .reportCardContainer {
      grid-template-columns: minmax(0, 1fr) 336px;
      max-height: 1068px;

      .reportHistory {
        border-left: 2px solid $sf-grey-100;
        padding: 0 0 0 36px;
      }
    }
  }
}

@media screen and (min-width: $sf-media-xxl-width) {
  .reportCard {
    height: 892px;

    .reportCardContainer {
      grid-template-columns: 300px minmax(0, 1fr) 336px;
      gap: 36px;
      grid-template-rows: 100%;
      height: 100%;

      .reportCardContent {
        grid-template-rows: auto 753px;

        .topMap {
          display: none;
        }

        .reportData {
          display: grid;
        }
      }

      .leftMap {
        display: block;
        height: 100%;
      }
    }
  }
}

:global(.nightMode) {
  .reportCard {
    .reportHistory {
      border-color: $sf-grey-700;
    }
  }
}

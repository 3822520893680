@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.reportHistoryAvatar {
  display: grid;
  grid-template-columns: 24px minmax(0, 1fr);
  align-items: center;
  gap: 6px;
  padding: 6px 0;
  box-sizing: border-box;
  width: fit-content;

  .title {
    overflow-wrap: break-word;
    text-transform: uppercase;
  }

  .text {
    overflow-wrap: break-word;
  }
}

@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.reportHistory {
  height: 100%;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  gap: 24px;

  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-right: 36px;

    > h4 {
      margin: 0;
    }
  }
}

@media screen and (min-width: $sf-media-lg-width) {
  .reportHistory {
    height: 100%;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    gap: 28px;
    padding-top: 3px;

    > div:first-of-type {
      padding-right: 36px;
    }
  }
}

@media screen and (min-width: $sf-media-xxl-width) {
  .reportHistory {
    grid-template-rows: auto 753px;
  }
}

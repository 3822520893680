@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.dottedArrowIcon {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(2, 4.2px);
  grid-template-rows: repeat(3, 4.3px);
  gap: 0.7px;

  .dot {
    grid-column: span 2;
    width: 4.2px;
    height: 4.2px;
    border-radius: 50%;
    background-color: $sf-blue-200;

    &.right {
      justify-self: flex-end;
    }
  }
}

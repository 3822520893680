@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

div.dialog {
  div.dialogPaper {
    width: 100%;
    max-width: 100%;
    height: 100%;
    margin: 24px;
    box-sizing: border-box;

    div.dialogContainer {
      height: 100%;
      background-color: $sf-background-light;
    }
  }
}

.manageWidgetsModal {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media screen and (min-width: $sf-media-md-width) {
  div.dialog {
    div.dialogPaper {
      margin: 0;
      width: 810px;
    }
  }
}

@media screen and (min-width: $sf-media-lg-width) {
  div.dialog {
    div.dialogPaper {
      width: 1022px;
    }
  }
}

@media screen and (min-width: $sf-media-xxl-width) {
  div.dialog {
    div.dialogPaper {
      width: 924px;
    }
  }
}

:global(.nightMode) {
  div.dialog {
    div.dialogPaper {
      div.dialogContainer {
        background-color: $sf-grey-900;
      }
    }
  }
}

@import '../../../../Mixins/OnboardingCommon.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.onboardingOwner {
  height: 100%;
  box-sizing: border-box;
  background-color: $sf-background-light;

  .hide {
    display: none;
  }
}

:global(.nightMode) {
  .onboardingOwner {
    background-color: $sf-background-dark;
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .onboardingOwner {
    .content {
      height: 100%;
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';
@import '../../../Constants/Colors.module.scss';

@mixin getCardColor($borderColor, $titleColor) {
  border-color: $borderColor;
  .pricingContainer {
    .title {
      background-color: $titleColor;
    }
  }
}

.pricingInformation {
  &.blue {
    @include getCardColor($sf-blue-500, $sf-blue-50);
  }

  &.purple {
    @include getCardColor($cc-purple-border-light, $cc-purple-main-light);
  }

  &.default {
    @include getCardColor($sf-grey-main-dark, $sf-grey-50);
  }

  border-radius: 6px;
  border: 1px solid;
  border-top-width: 12px;

  .pricingContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 36px 36px;

    .featuresContainer {
      display: flex;
      flex-direction: column;
      gap: 18px;
      margin: 12px 0;

      .featuresTitle {
        margin: 0;
      }

      ul {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 18px;

        li {
          display: grid;
          grid-template-columns: 14px minmax(0, 1fr);
          gap: 12px;
          align-items: baseline;

          svg {
            path {
              fill: $sf-blue-main-light !important;
            }
          }
        }
      }
    }

    .title {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 6px 18px;
      margin: 12px 0;
      border-radius: 20px;
      width: fit-content;

      .arrowIcon {
        div {
          background-color: $sf-grey-900;
        }
      }

      .plan {
        margin: 0;
      }
    }

    .price {
      display: flex;
      flex-direction: column;

      .basis {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: $cc-purple-text;
      }

      .cost {
        display: flex;
        gap: 8px;
        align-items: center;

        > h2 {
          color: $sf-blue-800;
        }
      }

      .description {
        color: $sf-blue-800;
      }
    }
  }
}

@media screen and (min-width: $sf-media-lg-width) {
  .pricingInformation {
    .pricingContainer {
      .featuresContainer {
        margin: 44px 0 0;
      }

      .title {
        margin: 12px 0 0;
      }
    }
  }
}

:global(.nightMode) {
  .pricingInformation {
    &.blue {
      @include getCardColor($sf-blue-200, $cc-blue-background-color);
    }

    &.purple {
      @include getCardColor($cc-purple-border-dark, $cc-purple-main-dark);
    }

    &.default {
      @include getCardColor($sf-grey-500, $sf-grey-700);
    }

    .pricingContainer {
      .featuresContainer {
        ul {
          > li {
            svg {
              path {
                fill: $sf-blue-main-dark !important;
              }
            }
          }
        }
      }

      .title {
        color: $sf-grey-50;
        background-color: $cc-blue-background-color;
      }

      .arrowIcon {
        div {
          background-color: $sf-grey-50;
        }
      }

      .price {
        .cost {
          > h2,
          .basis {
            color: $sf-blue-100;
          }
        }

        .description {
          color: $sf-blue-100;
        }
      }
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.authContainer {
  .leftPanel {
    display: none;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    background-color: #f1f2f5;
    background-image: url('../../Images/Login/DM_login.png');
  }

  .container {
    display: grid;
    grid-template-rows: 16px 1fr;

    .topBorder {
      height: 16px;
      background: linear-gradient(
        305.45deg,
        $sf-blue-500 40.72%,
        $sf-blue-200 100%
      );
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-self: center;
      padding: 48px 24px 0 24px;
      box-sizing: border-box;

      .brand {
        margin-bottom: 48px;
      }

      footer {
        margin-top: auto;

        > p {
          margin: 0;
          padding: 30px 0 24px 0;
          font-size: 10px;
          line-height: 12px;
          letter-spacing: 0.02em;
          text-align: center;
          color: $sf-grey-600;

          a {
            color: $sf-grey-600;
            text-decoration: none;
          }
        }
      }
    }
  }
}

:global(.nightMode) {
  .authContainer {
    .leftPanel {
      background-image: url('../../Images/Login/NM_login.png');
    }

    .container {
      .topBorder {
        background: linear-gradient(
          305.45deg,
          $sf-blue-800 40.72%,
          $sf-blue-500 100%
        );
      }

      .content {
        footer {
          > p {
            color: $sf-grey-400;

            a {
              color: $sf-grey-400;
            }
          }
        }
      }
    }
  }
}

:global(.isSafari) {
  .authContainer {
    height: -webkit-fill-available;

    .container {
      height: -webkit-fill-available;
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .authContainer {
    .container {
      .content {
        padding: 96px 2px 0 2px;
        max-width: 534px;
        width: 534px;

        .brand {
          margin-bottom: 96px;
        }

        footer {
          > p {
            padding: 30px 0 30px 0;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $sf-media-md-width) {
  .authContainer {
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-template-rows: 100%;

    .leftPanel {
      display: block;
    }

    .container {
      grid-template-rows: 1fr;
      box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.02),
        0px 24px 38px 3px rgba(0, 0, 0, 0.14),
        0px 9px 46px 8px rgba(0, 0, 0, 0.12);

      .topBorder {
        display: none;
      }

      .content {
        max-width: 392px;
        width: 392px;

        .brand {
          margin-bottom: 60px;
        }
      }
    }
  }
}

@media screen and (min-width: $sf-media-lg-width) {
  .authContainer {
    grid-template-columns: 314px 1fr;

    .container {
      .content {
        max-width: 500px;
        width: 500px;
      }
    }
  }
}

@media screen and (min-width: $sf-media-xl-width) {
  .authContainer {
    grid-template-columns: 525px 1fr;
  }
}

@media screen and (min-width: $sf-media-xxl-width) {
  .authContainer {
    grid-template-columns: 632px 1fr;

    .container {
      .content {
        max-width: 608px;
        width: 608px;

        .brand {
          margin-bottom: 96px;
        }
      }
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.fullReportModal {
  max-width: unset;
  width: 100%;
  height: 100%;
  margin: 16px;
  max-height: calc(100% - 32px);

  &.isLoading {
    justify-content: center;
    align-items: center;
  }

  .container {
    display: grid;
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(0, 1fr) 42px;
    gap: 19px;
    padding: 36px 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .fullReportModal {
    margin: 24px;
  }

  .container {
    gap: 24px;
  }
}

@media screen and (min-width: $sf-media-md-width) {
  .fullReportModal {
    max-width: 642px;
    margin: 24px auto;
  }
}

@media screen and (min-width: $sf-media-lg-width) {
  .fullReportModal {
    max-width: 604px;
  }
}

@media screen and (min-width: $sf-media-xl-width) {
  .fullReportModal {
    max-width: 764px;
  }
}

@media screen and (min-width: $sf-media-xxl-width) {
  .fullReportModal {
    max-width: 924px;
  }
}

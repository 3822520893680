@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.contactButton {
  display: flex;
  flex: 1 1 0px;
  gap: 12px;
  padding: 36px;
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid $sf-blue-main-light;
  background-color: $sf-surface-light;

  @media (hover: hover) {
    &:hover {
      background: rgba(204, 235, 255, 0.4);
    }
  }

  &:active {
    background: rgba(204, 235, 255, 0.6);
  }

  .buttonIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    border-radius: 36px;
    background-color: $sf-blue-main-light;

    path {
      fill: $sf-surface-light !important;
    }
  }
}

:global(.nightMode) {
  .contactButton {
    border: 1px solid $sf-blue-main-dark;
    background-color: $sf-surface-dark;

    @media (hover: hover) {
      &:hover {
        background: rgba(128, 198, 255, 0.2);
      }
    }

    &:active {
      background: rgba(128, 198, 255, 0.1);
    }

    .buttonIcon {
      background-color: $sf-blue-main-dark;

      path {
        fill: $sf-grey-900 !important;
      }
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.mainAd {
  position: fixed;
  bottom: 0;
  right: 0;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  box-sizing: border-box;
  margin: 16px;
  z-index: 99;

  box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 11px 15px -7px rgba(0, 0, 0, 0.02);

  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;

  .image {
    border-radius: 2px 2px 0px 0px;
    border-bottom: 1px solid $sf-grey-100;
    background-image: url('../../Images/DM_Ad_Analytics.png');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
  }

  .description {
    display: flex;
    flex-direction: column;
    gap: 24px;
    box-sizing: border-box;
    padding: 36px;

    .buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px;
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .mainAd {
    height: 474px;
    width: 348px;

    .description {
      .buttons {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
@media screen and (min-width: $sf-media-md-width) {
  .mainAd {
    height: 441px;
    width: 309px;
  }
}

@media screen and (min-width: $sf-media-lg-width) {
  .mainAd {
    height: 440px;
    width: 395px;
  }
}

@media screen and (min-width: $sf-media-xl-width) {
  .mainAd {
    height: 560px;
    width: 501px;
  }
}

@media screen and (min-width: $sf-media-xxl-width) {
  .mainAd {
    height: 675px;
    width: 608px;
  }
}

:global(.nightMode) {
  .mainAd {
    .image {
      background-image: url('../../Images/NM_Ad_Analytics.png');
      border-bottom: 1px solid $sf-grey-700;
    }
  }
}

@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.scrollable {
  box-sizing: border-box;
}

.sankey {
  box-sizing: border-box;
  padding: 0 36px 24px 36px;

  .echart {
    height: 100%;
    max-width: 648px;
    width: 648px;
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .sankey {
    padding-bottom: 36px;

    .echart {
      max-width: 100%;
      width: 100%;
    }
  }
}

:global(.SFAnalytics-ShareImage) {
  .scrollable {
    height: auto;
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.multipleReportsModalSelectOptionItem {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;

  .content {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .title {
      margin: 0;
      font-size: 16px;
      line-height: 24px;
    }

    .subTitle {
      margin: 0;
      font-size: 12px;
      line-height: 14px;
      color: $sf-grey-600;
    }
  }
}

:global(.nightMode) {
  .multipleReportsModalSelectOptionItem {
    .content {
      .subTitle {
        color: $sf-grey-400;
      }
    }
  }
}

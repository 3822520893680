@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.widgetTypeImage {
  width: 194px;
  height: 194px;

  > svg {
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: $sf-media-xl-width) {
  .widgetTypeImage {
    width: 232px;
    height: 232px;
  }
}

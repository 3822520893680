@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.dialogPaper {
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
}

.filterByModal {
  display: flex;
  flex-direction: column;
  gap: 36px;

  .filter {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .periodOptions {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      align-self: stretch;

      .periodOption {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    .officerOptions {
      display: flex;
      flex-direction: row;
      gap: 12px;
    }

    .sourceOptions {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}

.calendar {
  z-index: 1400;
}

@media screen and (min-width: $sf-media-sm-width) {
  .filterByModal {
    .filter {
      .periodOptions {
        display: grid;
        grid-template-columns: 180px auto;
      }

      .sourceOptions {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
  }
}

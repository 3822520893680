@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.widgetPanel {
  position: absolute;
  right: 0;
  height: 218px;
  top: calc(50% - 109px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  overflow: hidden;

  .panel {
    width: 100%;
    height: 100%;
    background-color: $sf-surface-light;
    padding: 0 24px;
  }

  .openBtn {
    cursor: pointer;
    background-color: $sf-surface-light;
    height: 218px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 94px 12px;
    box-sizing: border-box;
    border-width: 2px 0px 2px 2px;
    border-style: solid;
    border-color: $sf-grey-100;
    border-radius: 12px 0px 0px 12px;
  }
}

:global(.nightMode) {
  .widgetPanel {
    .panel {
      background-color: $sf-surface-dark;
    }

    .openBtn {
      background-color: $sf-surface-dark;
      border-color: $sf-grey-700;

      > svg {
        path {
          fill: $sf-grey-400 !important;
        }
      }
    }
  }
}

:global(.SFAnalytics-ShareImage) {
  .widgetPanel {
    display: none;
  }
}

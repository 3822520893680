@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.masonry {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-auto-rows: 1fr;
  gap: 24px;
  width: 100%;

  @media screen and (min-width: $sf-media-sm-width) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media screen and (min-width: $sf-media-lg-width) {
    &.isNavPanelCollapsed {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  @media screen and (min-width: $sf-media-xl-width) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

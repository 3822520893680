@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.advancedFilters {
  height: 100%;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);

  .menu {
    border-bottom: 1px solid $sf-grey-100;
  }

  .form {
    padding-top: 36px;
    box-sizing: border-box;

    .scrollable {
      padding-right: 16px;
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .advancedFilters {
    .form {
      .scrollable {
        padding-right: 36px;
      }
    }
  }
}

@media screen and (min-width: $sf-media-md-width) {
  .advancedFilters {
    height: 100%;
    grid-template-rows: 100%;
    grid-template-columns: 244px minmax(0, 1fr);

    .menu {
      box-sizing: border-box;
      padding-right: 36px;
      border-bottom: none;
      border-right: 1px solid $sf-grey-100;
    }

    .form {
      padding-top: 0;

      .scrollable {
        padding-left: 36px;
      }
    }
  }
}

:global(.nightMode) {
  .advancedFilters {
    .menu {
      border-color: $sf-grey-700;
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.item {
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 42px;

  .brief {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    color: $sf-grey-600;

    .label {
      font-weight: 700;
      color: $sf-grey-900;
    }

    &.error {
      color: $sf-red-main-light;

      .label {
        color: $sf-red-main-light;
      }
    }
  }

  .icon {
    align-self: center;
    justify-self: center;
  }
}

:global(.nightMode) {
  .item {
    .brief {
      color: $sf-grey-400;

      .label {
        color: $sf-grey-50;
      }

      &.error {
        color: $sf-red-main-dark;

        .label {
          color: $sf-red-main-dark;
        }
      }
    }
  }
}

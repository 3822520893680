@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';
@import '../LandingCommon.module.scss';

.landingProduct {
  @include LandingComponent;
  padding-top: 60px;

  .content {
    .title {
      margin: 0;
      padding-top: 6px;
      padding-bottom: 24px;
      color: $sf-blue-800;
      font-size: 36px;
      line-height: 48px;
      font-weight: 700;
      letter-spacing: -0.02em;
    }

    .description {
      margin: 0;
      padding-bottom: 24px;
      font-size: 18px;
      line-height: 26px;
    }
  }

  .image {
    height: 374px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    > img {
      position: absolute;
      top: 0;
      height: 432px;
      width: 593px;
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .landingProduct {
    .image {
      height: 454px;

      > img {
        top: -15px;
        height: 515px;
        width: 795px;
      }
    }
  }
}

@media screen and (min-width: $sf-media-md-width) {
  .landingProduct {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    direction: rtl;
    align-items: center;
    gap: 24px;
    padding-top: 0;

    .content {
      direction: ltr;
      padding: 98px 0 90px 0;
      min-height: 449px;
    }

    .image {
      height: 100%;

      & > img {
        top: 0;
        right: 0;
        height: 610px;
        width: 840px;
      }
    }
  }
}

@media screen and (min-width: $sf-media-lg-width) {
  .landingProduct {
    .content {
      min-height: 349px;
    }
    .image {
      & > img {
        top: -15px;
      }
    }
  }
}

@media screen and (min-width: $sf-media-xl-width) {
  .landingProduct {
    .content {
      padding: 124px 0 154px 0;
      min-height: 323px;
    }

    .image {
      & > img {
        height: 680px;
        width: 940px;
      }
    }
  }
}

@media screen and (min-width: $sf-media-xxl-width) {
  .landingProduct {
    .content {
      padding: 132px 0 154px 0;
      min-height: 323px;
    }

    .image {
      & > img {
        top: auto;
        height: 708px;
        width: 980px;
      }
    }
  }
}

:global(.nightMode) {
  .landingProduct {
    .content {
      .title {
        color: $sf-blue-100;
      }
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '../../../../Mixins/AuthCommon.module.scss';

.registrationCreateView {
  @include AuthView;

  .checkPolicies {
    label {
      gap: 0;
      color: $sf-grey-400;

      span {
        font-size: 14px;
        line-height: 20px;
      }

      span:nth-child(2) {
        padding-top: 11px;
      }
    }
  }
}

:global(.nightMode) {
  .registrationCreateView {
    @include AuthView(false);

    .checkPolicies {
      label {
        color: $sf-grey-600;
      }
    }
  }
}

@import '../../Mixins/DialogCommon.module.scss';

.incidentNumberModal {
  @include responsiveDialog();

  .content {
    .title {
      font-weight: 500;
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.sfNavPanel {
  height: 58px;
  background-color: $sf-grey-50;

  .panelWrapper {
    display: flex;

    .menuButton {
      margin: 5px;
    }
  }
}

.navPanelDrawer {
  .navPanelDrawerPaper {
    overflow-y: unset;
  }

  .topBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .buttons {
      justify-self: flex-end;

      button {
        margin: 5px 10px 10px 10px;
      }
    }

    .ccBrand {
      height: 18px;
      margin-left: 12px;
    }
  }
}

:global(.nightMode) {
  .sfNavPanel {
    background-color: $sf-grey-900;
  }

  .navPanelDrawer {
    .topBar {
      .ccBrand {
        path {
          fill: $sf-grey-100;
        }
      }
    }
  }
}

:global(.isSafari) {
  .navPanelDrawer {
    .navPanelDrawerPaper {
      height: -webkit-fill-available;
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .sfNavPanel {
    height: 66px;

    .panelWrapper {
      .menuButton {
        margin: 10px;
      }
    }
  }

  .navPanelDrawer {
    .navPanelDrawerPaper {
      width: 396px;
    }

    .topBar {
      .buttons {
        button {
          margin: 10px 10px 20px 10px;
        }
      }
    }
  }
}

@media screen and (min-width: $sf-media-md-width) {
  .navPanelDrawer {
    .navPanelDrawerPaper {
      width: 358px;
    }
  }
}

@media screen and (min-width: $sf-media-lg-width) {
  .sfNavPanel {
    align-items: flex-start;
    width: 100%;
    height: 100%;

    .content {
      height: 100%;
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFTypography/SFTypography.module.scss';

.doughnutInformation {
  height: 100%;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  gap: 12px;

  .content {
    visibility: visible;
  }

  .title {
    padding: 12px 12px 12px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid $sf-grey-100;

    > span {
      @include component-2;
      color: $sf-grey-600;
    }
  }
}

:global(.nightMode) {
  .doughnutInformation {
    .title {
      border-color: $sf-grey-700;

      > span {
        color: $sf-grey-400;
      }
    }
  }
}

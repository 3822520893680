@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.noReports {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  .content {
    text-align: center;

    .icon {
      svg {
        path {
          fill: $sf-blue-500 !important;
        }
      }
    }

    .title {
      margin: 24px 0 0 0;
    }

    .subTitle {
      margin: 12px 0 0 0;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0px;
      color: $sf-grey-600;
    }
  }
}

:global(.nightMode) {
  .noReports {
    .content {
      .icon {
        svg {
          path {
            fill: $sf-blue-200 !important;
          }
        }
      }

      .subTitle {
        color: $sf-grey-400;
      }
    }
  }
}

@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';
@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.item {
  margin-left: 32px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid $sf-grey-100;

  &:nth-last-child(1) {
    border: none;
  }

  .title {
    margin: 0;
    text-transform: capitalize;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .item {
    display: grid;
    grid-template-columns: 140px 1fr;
    gap: 32px;
  }
}

:global(.nightMode) {
  .item {
    border-bottom-color: $sf-grey-700;
  }
}

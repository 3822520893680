@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.itemListSection {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .title {
    margin: 0;
    font-weight: 500;
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '../../../../../Mixins/DialogCommon.module.scss';

.missingInformationModal {
  @include responsiveDialog();

  .content {
    .messages {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .messageList {
        margin: 0;
        padding-left: 14px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        color: $sf-red-main-light;
      }
    }

    > button {
      margin-top: 12px;
    }
  }
}

:global(.nightMode) {
  .missingInformationModal {
    .content {
      .messages {
        .messageList {
          color: $sf-red-main-dark;
        }
      }
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFTypography/SFTypography.module.scss';

.filterSelector {
  min-height: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .checkboxHeader {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    border-bottom: 1px solid $sf-grey-100;
    padding-bottom: 15px;
    box-sizing: border-box;

    .title {
      @include component-2;
      flex: 1;
      color: $sf-grey-600;
    }
  }

  .scrollable {
    min-height: 0;
  }
}

:global(.nightMode) {
  .filterSelector {
    .checkboxHeader {
      border-color: $sf-grey-700;

      .title {
        color: $sf-grey-400;
      }
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.sfNavContent {
  min-height: 0;
  width: auto;

  .content {
    display: grid;
    grid-template-rows: minmax(0, 1fr) 1px;
    height: 100%;

    .scrollable {
      .scrollableContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 6px;
      padding: 0;
      margin: 0 12px 0 0;

      li {
        list-style: none;
      }
    }

    .settings {
      border-top: 1px solid $sf-grey-100;
      padding-top: 24px;
      margin-top: 12px;
    }
  }

  &.isCollapsed {
    width: 68px;

    .content {
      ul {
        margin: 0 6px 0 0;
      }
    }
  }
}

:global(.nightMode) {
  .sfNavContent {
    .content {
      .settings {
        border-color: $sf-grey-700;
      }
    }
  }
}

@media screen and (min-width: $sf-media-lg-width) {
  .sfNavContent {
    height: 100%;
    width: 210px;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    background-color: $sf-grey-50;

    .logo {
      height: 110px;
      padding: 20px 24px 24px;
      display: grid;
      box-sizing: border-box;
      grid-template-columns: minmax(0, 1fr) auto;
      align-items: center;

      &.logoCollapsed {
        grid-template-columns: auto;
        padding: 20px 13px 24px;
      }
    }
  }

  :global(.nightMode) {
    .sfNavContent {
      background-color: $sf-grey-900;
    }
  }
}

@media screen and (min-width: $sf-media-xl-width) {
  .sfNavContent {
    width: 265px;

    .content {
      ul {
        margin: 0 24px 0 0;
      }
    }
  }
}

@media screen and (min-width: $sf-media-xxl-width) {
  .sfNavContent {
    width: 315px;
  }
}

@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.collaboratedReports {
  height: 100%;

  .scrollable {
    padding: 0 16px 16px 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .moreReportsSpinner {
      display: flex;
      justify-content: center;
      padding-top: 12px;
      box-sizing: border-box;
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .collaboratedReports {
    .scrollable {
      padding: 0 24px 24px 24px;
    }
  }
}

@media screen and (min-width: $sf-media-lg-width) {
  .collaboratedReports {
    .scrollable {
      padding: 0 48px 48px 48px;
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.subscriptionStateView {
  background-color: $sf-background-light;
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    .icon {
      path {
        fill: $sf-blue-500 !important;
      }
    }

    .title {
      margin: 0;
      text-align: center;
    }
  }

  .contact {
    margin: 36px 0 0 0;
    text-align: center;
  }
}

:global(.isSafari) {
  .subscriptionStateView {
    height: -webkit-fill-available;
  }
}

:global(.nightMode) {
  .subscriptionStateView {
    background-color: $sf-background-dark;

    .content {
      .icon {
        path {
          fill: $sf-blue-200 !important;
        }
      }
    }
  }
}

@media screen and (min-width: $sf-media-md-width) {
  .subscriptionStateView {
    padding: 24px;
  }
}

@media screen and (min-width: $sf-media-lg-width) {
  .subscriptionStateView {
    padding: 48px;
  }
}

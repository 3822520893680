@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.messageSupport {
  margin: 0;
  color: $sf-grey-600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
}

:global(.nightMode) {
  .messageSupport {
    color: $sf-grey-400;
  }
}

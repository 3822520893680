@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.tinyWidget {
  display: flex;
  padding: 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  align-self: stretch;

  h5,
  h1 {
    margin: 0;
  }

  p {
    cursor: default;
  }

  &.disabled {
    p {
      color: $sf-grey-300;
    }
  }
}

:global(.nightMode) {
  .tinyWidget {
    &.disabled {
      p {
        color: $sf-grey-600;
      }
    }
  }
}

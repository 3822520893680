@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.wizardCardSlideStep {
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: $sf-surface-light;
}

:global(.nightMode) {
  .wizardCardSlideStep {
    background-color: $sf-surface-dark;
  }
}

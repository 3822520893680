@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.patternMap {
  position: relative;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100%;
  gap: 24px;

  .info {
    display: none;
  }
}

:global(.SFAnalytics-ShareImage) {
  .patternMap {
    height: auto;
    display: flex;
    flex-direction: column;

    [class*='GoogleMap'] {
      height: 505px;
    }

    .info {
      display: block;
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';
@import '../LandingCommon.module.scss';

.landingHighlight {
  @include LandingComponent;
  background-color: $sf-blue-900;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
}

@media screen and (min-width: $sf-media-sm-width) {
  .landingHighlight {
    padding-top: 75px;
    padding-bottom: 75px;
    flex-direction: row;
    justify-content: space-around;
  }
}

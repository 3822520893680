@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';
@import '~sfui/dist/Styles/SFTypography/SFTypography.module.scss';

.multipleReportsModalSelect {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 36px 36px 36px;
  box-sizing: border-box;

  .label {
    margin: 0;
    color: $sf-grey-600;

    .count {
      color: $sf-surface-dark;
    }
  }

  .select {
    display: flex;
    flex-direction: column;
    gap: 36px;
  }

  .optionList {
    display: none;
  }
}

@media screen and (min-width: $sf-media-md-width) {
  .multipleReportsModalSelect {
    .select {
      display: none;
    }
    .optionList {
      display: flex;
    }
  }
}

:global(.nightMode) {
  .multipleReportsModalSelect {
    .label {
      color: $sf-grey-400;

      .count {
        color: $sf-grey-200;
      }
    }
  }
}

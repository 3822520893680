@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

@mixin wizardView($lightTheme: true) {
  height: 100%;
  display: grid;

  .scrollable {
    min-height: 0;
  }

  .scrollableContainer {
    padding: 16px;
    box-sizing: border-box;

    .content {
      margin: 0 auto;

      .title {
        margin: 3px 0;
        display: flex;
        align-items: center;

        .chip {
          margin-left: 12px;
        }
      }

      .subTitle {
        margin: 0 0 32px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;

        @if $lightTheme {
          color: $sf-grey-600;
        } @else {
          color: $sf-grey-400;
        }
      }
    }
  }

  &.withFooterButtons {
    grid-template-rows: 1fr 94px;

    .content {
      padding-bottom: 0;
    }

    footer {
      padding: 36px 16px 16px 16px;
      justify-self: center;
      box-sizing: border-box;
      width: 100%;
      max-width: 375px;
    }
  }

  @media screen and (min-width: $sf-media-sm-width) {
    .scrollableContainer {
      padding: 24px;

      .form {
        width: 534px;
        margin: 0 auto;
      }
    }

    &.withFooterButtons {
      grid-template-rows: 1fr 103px;

      footer {
        max-width: 100%;
        padding: 0 24px 24px 24px;
      }
    }
  }

  @media screen and (min-width: $sf-media-md-width) {
    .scrollableContainer {
      .form {
        width: 476px;
      }
    }
  }

  @media screen and (min-width: $sf-media-lg-width) {
    .scrollableContainer {
      padding: 0 48px 48px 48px;

      .form {
        width: 603px;
      }
    }

    &.withFooterButtons {
      grid-template-rows: 1fr 126px;

      footer {
        max-width: 100%;
        padding: 0 48px 48px 48px;
      }
    }
  }

  @media screen and (min-width: $sf-media-xl-width) {
    .scrollableContainer {
      .form {
        width: 501px;
      }
    }
  }

  @media screen and (min-width: $sf-media-xxl-width) {
    .scrollableContainer {
      .form {
        width: 608px;
      }
    }
  }
}

@import '../../../../Mixins/OnboardingCommon.module.scss';

.onboardingOfficer {
  @include onboardingView;
  background-color: $sf-background-light;
}

:global(.nightMode) {
  .onboardingOfficer {
    @include onboardingView(false);
    background-color: $sf-background-dark;
  }
}

/* bring in normalize.css styles */
@import-normalize;

@import '~sfui/dist/Styles/SFTypography/SFTypography.module.scss';
@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

@import '../node_modules/react-image-crop/dist/ReactCrop.css';

html {
  height: 100%;
}

body {
  margin: 0;
  min-height: 100%;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $sf-surface-light;

  &.nightMode {
    background-color: $sf-surface-dark;
  }
}

#root {
  height: 100vh;
}

@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.reportListHeader {
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .actions {
      display: flex;
      gap: 18px;

      .dropDownLabel {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        .label {
          font-weight: 500;
        }
      }
    }

    .labels {
      flex: 1;
      display: grid;
      gap: 24px;
      grid-template-columns: auto minmax(0, 1fr);
      align-items: center;

      &.noReportsLabel {
        grid-template-columns: minmax(0, 1fr);
      }
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .reportListHeader {
    padding: 0 24px;
  }
}

@media screen and (min-width: $sf-media-md-width) {
  .reportListHeader {
    .content {
      flex-direction: row;
      flex-flow: row-reverse;
      justify-content: space-between;
    }
  }
}

@media screen and (min-width: $sf-media-lg-width) {
  .reportListHeader {
    padding: 0 48px;
  }
}

@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';
@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.landingView {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 58px minmax(0, 1fr);
  .scrollable {
    overflow-x: hidden;

    .scrollableContent {
      overflow-x: hidden;
      background-size: 1520px 700px;
      background-position: top;
      background-repeat: no-repeat;
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .landingView {
    .scrollable {
      .scrollableContent {
        background-size: 1920px 884px;
      }
    }
  }
}

@media screen and (min-width: $sf-media-md-width) {
  .landingView {
    .scrollable {
      .scrollableContent {
        background-position: top;
        background-size: 1280px 590px;
      }
    }
  }
}

@media screen and (min-width: $sf-media-lg-width) {
  .landingView {
    .scrollable {
      .scrollableContent {
        background-size: 100% 590px;
      }
    }
  }
}

@media screen and (min-width: $sf-media-xl-width) {
  .landingView {
    .scrollable {
      .scrollableContent {
        background-size: 100% 750px;
      }
    }
  }
}

@media screen and(min-width: $sf-media-xxl-width) {
  .landingView {
    .scrollable {
      .scrollableContent {
        background-size: 100% 884px;
      }
    }
  }
}

:global(.nightMode) {
  .landingView {
    .scrollable {
      .scrollableContent {
        background-color: $sf-background-dark;
      }
    }
  }
}

:global(.isSafari) {
  .landingView {
    height: -webkit-fill-available;
  }
}

.staticMap {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  height: 100%;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';
@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.footerButtons {
  display: flex;
  flex-direction: column;
  gap: 36px;

  .divider {
    display: none;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    > button {
      width: 100%;
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .footerButtons {
    .divider {
      display: block;
    }

    .buttons {
      > button {
        width: auto;
      }
    }
  }
}

@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.saveObjectPanelHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    flex: 1;
    text-align: center;
    margin: 0;
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .saveObjectPanelHeader {
    .header {
      button {
        display: none;
      }
    }
  }
}

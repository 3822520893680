@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.fieldItem {
  margin-left: 32px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .label {
    margin: 0;
  }

  .value {
    margin: 0 0 0 12px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    word-break: break-all;
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .fieldItem {
    display: grid;
    grid-template-columns: 140px 1fr;
    gap: 32px;

    .value {
      margin: 0;
    }
  }
}

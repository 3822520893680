@import '~sfui/dist/Styles/SFTypography/SFTypography.module.scss';
@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.rateCard {
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: left;

  .data {
    display: flex;
    align-items: center;
    gap: 12px;

    .value {
      font-size: 50px;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: -0.02em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .chip {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 5px 12px;
      gap: 6px;
      background: $sf-blue-50;
      border-radius: 16px;
      font-weight: 800;
      font-size: 16px;
      line-height: 22px;
      color: $sf-blue-600;
    }
  }

  .legend {
    margin: 0;
    @include component-messages;
  }
}

:global(.nightMode) {
  .rateCard {
    .data {
      .chip {
        background-color: $sf-blue-800;
        color: $sf-blue-200;

        > svg {
          > path {
            fill: $sf-blue-200 !important;
          }
        }
      }
    }

    .legend {
      color: $sf-grey-400;
    }
  }
}

:global(.SFAnalytics-ShareImage) {
  .rateCard {
    .data {
      .value {
        overflow: unset;
        white-space: normal;
      }
    }
  }
}

@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.wizardStepContent {
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  gap: 24px;
  box-sizing: border-box;
  height: 100%;
  padding: 12px 0 16px 16px;

  .header {
    display: flex;
    flex-direction: column;
    gap: 3px;
    padding-right: 16px;
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .wizardStepContent {
    padding-left: 36px;
    padding-bottom: 36px;

    .header {
      padding-right: 36px;
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.planDescriptionItem {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  gap: 6px;
  align-items: baseline;

  svg {
    path {
      fill: $sf-blue-main-light !important;
    }
  }
}

:global(.nightMode) {
  .planDescriptionItem {
    svg {
      path {
        fill: $sf-blue-main-dark !important;
      }
    }
  }
}

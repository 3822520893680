@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.analyticsWidgets {
  position: relative;
  height: 100%;

  .spinner {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
  }

  .scrollable {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 16px 16px;
    box-sizing: border-box;
  }

  @media screen and (min-width: $sf-media-sm-width) {
    .scrollable {
      padding: 0 24px 24px;
    }
  }

  @media screen and (min-width: $sf-media-lg-width) {
    .scrollable {
      padding: 0 48px 48px;
    }
  }
}

@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.setUpAgency {
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;

  .errorMessage {
    margin-bottom: 24px;
  }

  .scrollable {
    padding-right: 16px;
  }

  .actionBtn {
    padding-right: 16px;
    padding-top: 20px;
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .setUpAgency {
    .scrollable {
      padding-right: 36px;
    }

    .actionBtn {
      padding-right: 36px;
    }
  }
}

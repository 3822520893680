@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.selectPlan {
  padding-right: 16px;
  display: grid;
  gap: 24px;
  justify-content: center;

  .strong {
    font-weight: 500;
  }

  .planDescriptionCard {
    max-width: 400px;
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .selectPlan {
    padding-right: 36px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 100%;
  }
}

.filterChipList {
  display: flex;
  gap: 12px;
  align-items: center;
}

.menu {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .menuItem {
    height: auto;
    min-height: auto;
    padding: 0;

    &:hover,
    &:active {
      cursor: inherit;
      background-color: transparent;
    }

    > div {
      width: 100%;

      .menuItemChip {
        justify-content: space-between;

        > span {
          direction: rtl;
        }
      }
    }
  }
}

@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.contactMap {
  height: 300px;
}

@media screen and (min-width: $sf-media-xxl-width) {
  .contactMap {
    height: 528px;
  }
}

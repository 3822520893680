@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.logoOkta {
  width: 26px;
  height: 26px;

  > svg {
    width: 26px;
    height: 26px;
  }
}

:global(.nightMode) {
  .logoOkta {
    path {
      fill: $sf-grey-50;
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFTypography/SFTypography.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.widgetsFilter {
  display: flex;
  flex-direction: column-reverse;
  gap: 12px;
  padding: 6px 0 20px;

  .filters {
    flex: 1;
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .widgetsFilter {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;
  }
}

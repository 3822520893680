@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '../../../Mixins/AuthCommon.module.scss';

.forgotPasswordSent {
  @include AuthView;

  .message {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    color: $sf-grey-600;

    .email {
      font-weight: 500;
      color: $sf-text-black;
    }
  }
}

:global(.nightMode) {
  .forgotPasswordSent {
    @include AuthView(false);

    .message {
      color: $sf-grey-400;

      .email {
        color: $sf-text-white;
      }
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.noDataPaddingContainer {
  height: 100%;
  padding: 0 36px 36px 36px;
  box-sizing: border-box;
}

.widgetCardContainer {
  height: 100%;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-columns: 100%;
  gap: 24px;
  padding: 0;
  box-sizing: border-box;

  .content {
    &.isWithPadding {
      padding: 0 36px 36px 36px;
      box-sizing: border-box;
    }

    .footer {
      display: none;
    }
  }
}

:global(.SFAnalytics-ShareImage) {
  .card {
    height: auto;
  }

  .widgetCardContainer {
    height: auto;

    .content {
      .footer {
        display: flex;
        padding-top: 36px;
        flex-direction: column;
        gap: 10px;
        justify-content: space-between;
        align-items: center;

        [class*='MuiFormControl-root'] {
          width: 100%;

          .footerChip {
            justify-content: center;
          }
        }

        .footerLegend {
          width: 100%;
          text-align: center;
        }

        &.rowFooter {
          padding-right: 36px;
          padding-left: 36px;
          padding-bottom: 36px;
          flex-direction: row;
          justify-content: space-between;

          [class*='MuiFormControl-root'] {
            width: auto;
          }

          .footerLegend {
            width: auto;
          }
        }
      }
    }
  }

  @media screen and (min-width: $sf-media-sm-width) {
    .widgetCardContainer {
      .content {
        .footer {
          &.wideFooter {
            flex-direction: row;
            justify-content: space-between;

            [class*='MuiFormControl-root'] {
              width: auto;
            }

            .footerLegend {
              width: auto;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: $sf-media-md-width) {
    .widgetCardContainer {
      .content {
        .footer {
          flex-direction: row;
          justify-content: space-between;

          [class*='MuiFormControl-root'] {
            width: auto;
          }

          .footerLegend {
            width: auto;
          }
        }
      }
    }
  }
}

.networkStatus {
  .pendingReportsBadge {
    [class*='MuiBadge-badge'] {
      top: 25%;
      left: 25%;
    }

    &.isDisabled {
      pointer-events: none;
    }
  }
}

.popper {
  z-index: 1299;

  .tooltipPlacementLeft {
    margin: 0 8px;
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.wizardCard {
  height: 100%;
  width: 100%;
  position: relative;
}

.wizardCardDrawer {
  height: 100%;
  overflow: hidden;
}

.loader {
  z-index: 3;
  background-color: $sf-surface-light !important;
}

:global(.nightMode) {
  .loader {
    background-color: $sf-surface-dark !important;
  }
}

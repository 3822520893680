@import '~sfui/dist/Styles/SFTypography/SFTypography.module.scss';
@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.patternMapItem {
  display: flex;
  flex-direction: column;

  .title {
    @include component-2;
    text-transform: uppercase;
    padding-bottom: 15px;
    color: $sf-blue-500;
    border-bottom: 1px solid $sf-grey-100;
  }
}

:global(.nightMode) {
  .patternMapItem {
    .title {
      color: $sf-blue-200;
      border-color: $sf-grey-700;
    }
  }
}

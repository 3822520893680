@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.rankingCarHeader {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  padding-bottom: 12px;
  padding-right: 12px;
  border-bottom: 2px solid $sf-grey-100;

  .headerElementInfo {
    display: flex;
    gap: 8px;

    .icon {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
}

:global(.nightMode) {
  .rankingCarHeader {
    border-color: $sf-grey-700;
  }
}

:global(.SFAnalytics-ShareImage) {
  .rankingCarHeader {
    .headerElementInfo {
      .icon {
        display: none;
      }
    }
  }
}

@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.agencyReports {
  height: 100%;

  .content {
    height: 100%;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);

    .listSpinner {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .scrollable {
      padding: 24px 16px 16px 16px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .listHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .moreReportsSpinner {
        display: flex;
        justify-content: center;
        padding-top: 12px;
        box-sizing: border-box;
      }
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .agencyReports {
    .content {
      .scrollable {
        padding: 24px;
      }
    }
  }
}

@media screen and (min-width: $sf-media-lg-width) {
  .agencyReports {
    .content {
      .scrollable {
        padding: 24px 48px 48px 48px;
      }
    }
  }
}

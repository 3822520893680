@import '~sfui/dist/Styles/SFTypography/SFTypography.module.scss';
@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.wizardCardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  height: 42px;
  padding: 12px 8px 0;

  .title {
    text-transform: uppercase;

    .active {
      color: $sf-grey-900;
      font-weight: 700;
    }
  }
}

:global(.nightMode) {
  .wizardCardHeader {
    .title {
      .active {
        color: $sf-grey-50;
      }
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .wizardCardHeader {
    padding: 36px 36px 0 36px;
    justify-content: unset;

    .btnClose {
      margin-left: auto;
    }
  }
}

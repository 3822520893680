@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.filtersCategoryMarker {
  width: 8px;
  height: 8px;
  background-color: $sf-blue-main-light;
  border-radius: 50%;
}

:global(.nightMode) {
  .filtersCategoryMarker {
    background-color: $sf-blue-main-dark;
  }
}

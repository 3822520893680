@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.reportCardInfo {
  display: grid;
  grid-template-columns: 1fr;

  .content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: fit-content;
    box-sizing: border-box;

    &:first-child {
      padding-bottom: 24px;
      border-bottom: 1px solid $sf-grey-100;
    }

    &:nth-child(2) {
      padding-top: 24px;
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .reportCardInfo {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    .content {
      &:first-child {
        padding-bottom: 0;
        padding-right: 24px;
        border-right: 1px solid $sf-grey-100;
        border-bottom: 0;
      }

      &:nth-child(2) {
        padding-top: 0;
        padding-left: 24px;
      }
    }
  }
}

@media screen and (min-width: $sf-media-xxl-width) {
  .reportCardInfo {
    .content {
      min-height: 100%;
    }
  }
}

:global(.nightMode) {
  .reportCardInfo {
    .content {
      &:first-child {
        border-bottom: 1px solid $sf-grey-700;
      }
    }
  }
  @media screen and (min-width: $sf-media-sm-width) {
    .reportCardInfo {
      .content {
        &:first-child {
          border-right: 1px solid $sf-grey-700;
          border-bottom: 0;
        }
      }
    }
  }
}

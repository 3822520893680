@import '../ReportWizardView.module.scss';

.incidentNumberView {
  @include wizardView();

  .form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .caseNumber {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .caseNumberTitle {
        display: flex;
        gap: 12px;
      }
    }
  }
}

:global(.nightMode) {
  .incidentNumberView {
    @include wizardView(false);
  }
}

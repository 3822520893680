@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFTypography/SFTypography.module.scss';

.doughnutItem {
  padding: 12px 0;
  margin-right: 12px;
  gap: 12px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $sf-grey-100;
  box-sizing: border-box;

  &:nth-last-child(1) {
    border: none;
  }

  .text {
    @include component-1;
  }

  .label {
    display: grid;
    align-items: center;
    grid-template-columns: 10px 1fr;
    gap: 12px;

    .color {
      height: 10px;
      width: 10px;
      border-radius: 50%;
    }
  }

  .value {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

:global(.nightMode) {
  .doughnutItem {
    border-color: $sf-grey-700;
  }
}

.rankingCard {
  height: 100%;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  gap: 3px;
}

:global(.SFAnalytics-ShareImage) {
  .rankingCard {
    height: auto;
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.widgetTypeForm {
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 24px;
  box-sizing: border-box;
  border: 1px solid $sf-grey-100;
  border-radius: 10px;
  background-color: $sf-surface-light;

  .checkboxList {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .widgetTypeForm {
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
  }
}

:global(.nightMode) {
  .widgetTypeForm {
    border-color: $sf-grey-700;
    background-color: $sf-background-dark;
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '../../Mixins/AuthCommon.module.scss';

.loginView {
  @include AuthView;

  .content {
    .forgotPassword {
      text-align: right;
    }
  }

  .orBar {
    display: flex;
    padding: 6px 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
  }
}

:global(.nightMode) {
  .loginView {
    @include AuthView(false);
  }
}

.tagsForm {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .title {
    display: flex;
    gap: 10px;

    > h3 {
      margin: 0;
      font-weight: 500;
    }
  }
}

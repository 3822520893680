@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '../../../Mixins/AuthCommon.module.scss';

.forgotPasswordInstructions {
  @include AuthView;

  .message {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    color: $sf-grey-600;
  }
}

:global(.nightMode) {
  .forgotPasswordInstructions {
    @include AuthView(false);

    .message {
      color: $sf-grey-400;
    }
  }
}

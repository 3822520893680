@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

@mixin responsiveDialog {
  margin: 0px;
  max-width: unset;
  overflow-y: unset;

  .content {
    @include responsiveDialogContent();

    padding: 36px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .title {
      margin: 0;
      line-height: 28px;
    }

    .subTitle {
      margin: 0;
      font-size: 16px;
      line-height: 24px;
    }

    @media screen and (min-width: $sf-media-sm-width) {
      > button {
        width: fit-content;
        align-self: flex-end;
      }
    }
  }
}

@mixin responsiveDialogContent {
  width: 270px;

  @media screen and (min-width: $sf-media-sm-width) {
    width: 462px;
  }

  @media screen and (min-width: $sf-media-md-width) {
    width: 404px;
  }

  @media screen and (min-width: $sf-media-lg-width) {
    width: 532px;
  }

  @media screen and (min-width: $sf-media-xl-width) {
    width: 430px;
  }

  @media screen and (min-width: $sf-media-xxl-width) {
    width: 536px;
  }
}

@import '../../Mixins/ErrorCommon.module.scss';

.noConnectionView {
  height: 100%;
  @include errorView;
}

:global(.isSafari) {
  .noConnectionView {
    height: -webkit-fill-available;
  }
}

:global(.nightMode) {
  .noConnectionView {
    @include errorView(false);
  }
}

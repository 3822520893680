@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.doughnut {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 200px minmax(0, 1fr);
  gap: 24px;
  height: 100%;

  .graph {
    .image {
      height: 200px;
    }
  }
}

:global(.SFAnalytics-ShareImage) {
  .doughnut {
    height: fit-content;
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.contactMapNoCoordinates {
  height: 300px;
  box-sizing: border-box;
  position: relative;

  .map {
    width: 100%;
    height: 100%;
    background: url('../../../../Images/dm_map.png');
    background-size: cover;
    background-repeat: 'no-repeat';
    filter: blur(4px);
    position: absolute;
    top: 0;
    left: 0;
  }

  .container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;

    .circle {
      width: 144px;
      height: 144px;
      border-radius: 50%;
      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.6);
      z-index: 1;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        background-color: $sf-common-white;
        border-radius: 24px;
        box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.02),
          0px 16px 24px 2px rgba(0, 0, 0, 0.14),
          0px 6px 30px 5px rgba(0, 0, 0, 0.12);

        svg path {
          fill: $sf-blue-800 !important;
        }
      }
    }
  }
}

.toolTipText {
  margin: 0;
  padding: 0;
}

:global(.nightMode) {
  .contactMapNoCoordinates {
    .map {
      background: url('../../../../Images/nm_map.png');
      background-size: cover;
      background-repeat: 'no-repeat';
    }

    .container {
      .circle {
        background-color: rgba(0, 0, 0, 0.4);

        .icon {
          background-color: $sf-common-black;

          svg path {
            fill: $sf-blue-300 !important;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $sf-media-xxl-width) {
  .contactMapNoCoordinates {
    height: 528px;
  }
}

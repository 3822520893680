@import '../../../../Mixins/ReportInfoCommon.module.scss';

.tagsInformation {
  @include infoList;

  .infoItem {
    .chips {
      display: flex;
      gap: 3px;
      flex-wrap: wrap;
    }
  }
}

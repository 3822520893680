@import '~sfui/dist/Styles/SFTypography/SFTypography.module.scss';
@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.multipleReportsModalSelectListOption {
  height: 52px;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 6px 24px;
  box-sizing: border-box;

  .listOptionIcon {
    transform: scaleX(-1);

    path {
      fill: $sf-grey-600 !important;
    }
  }

  .listOptionLabel {
    display: flex;
    flex-direction: column;
    gap: 2px;

    > p {
      margin: 0;
    }

    .labelDate {
      @include component-1;
    }

    .labelAuthor {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: $sf-grey-600;
    }
  }

  @media (hover: hover) {
    &:hover {
      background-color: rgba(204, 235, 255, 0.4);
    }
  }

  &.isSelected {
    background-color: rgba(204, 235, 255, 0.6);

    .listOptionIcon {
      path {
        fill: $sf-blue-500 !important;
      }
    }
  }
}

:global(.nightMode) {
  .multipleReportsModalSelectListOption {
    .listOptionIcon {
      path {
        fill: $sf-grey-400 !important;
      }
    }

    .labelAuthor {
      color: $sf-grey-400;
    }

    @media (hover: hover) {
      &:hover {
        background-color: rgba(128, 198, 255, 0.2);
      }
    }

    &.isSelected {
      background-color: rgba(128, 198, 255, 0.1);

      .listOptionIcon {
        path {
          fill: $sf-blue-200 !important;
        }
      }
    }
  }
}

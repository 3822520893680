.widgetsSectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .headerLeft {
    display: flex;
    gap: 24px;

    h2 {
      margin: 0;
    }
  }
}

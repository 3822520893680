@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.wizardFinishContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .icon {
    padding-bottom: 24px;
  }

  .title {
    margin: 0;
    padding-bottom: 12px;

    > p {
      margin: 0;
    }
  }

  .button {
    padding-top: 36px;
  }
}

:global(.nightMode) {
  .wizardFinishContent {
    .icon > svg > path {
      fill: $sf-blue-main-dark !important;
    }
  }
}

@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.basicFilters {
  display: flex;
  flex-direction: column;
  gap: 36px;

  .section {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .title {
      display: flex;
      flex-direction: column;
      gap: 3px;
    }

    .chips {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .contactTypes {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .radioButtons > div {
          margin: 0;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .basicFilters {
    .section {
      .chips {
        .contactTypes {
          .radioButtons > div {
            flex-direction: row;
            gap: 24px;
          }
        }
      }
    }
  }
}

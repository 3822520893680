@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.reportCardHeaderActions {
  display: flex;
  flex-shrink: 0;

  .actionsMenu {
    .btnMenu {
      transform: rotate(90deg);
    }
  }

  .actionsButtons {
    display: none;
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .reportCardHeaderActions {
    .actionsMenu {
      display: none;
    }

    .actionsButtons {
      min-width: max-content;
      display: flex;
      gap: 12px;
    }
  }
}

@media screen and (min-width: $sf-media-lg-width) {
  .reportCardHeaderActions {
    .actionsButtons {
      .btnHistory {
        display: none;
      }
    }
  }
}

@import '~sfui/dist/Styles/SFTypography/SFTypography.module.scss';

.widgetDrawer {
  height: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);

  .header {
    padding: 8px 12px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
  }

  .content {
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    padding: 0 16px 16px;
    box-sizing: border-box;

    .title {
      @include component-title;
      padding: 12px 0 24px 0;
      box-sizing: border-box;
    }
  }
}

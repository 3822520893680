@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.limitedResponseTooltip {
  width: 225px;
}

@media screen and (min-width: $sf-media-sm-width) {
  .limitedResponseTooltip {
    width: unset;
  }
}

.widgetCardHeader {
  padding: 36px 36px 0 36px;
  display: flex;
  justify-content: space-between;
  gap: 8px;

  .title {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    gap: 8px;
    align-items: center;

    > h3 {
      margin: 0;
      text-align: left;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }
  }

  .actions {
    display: flex;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  padding: 13px 0;
  box-sizing: border-box;
  gap: 12px;

  .shareItem {
    display: flex;
    align-items: center;
    gap: 8px;
    height: fit-content;
  }
}

:global(.SFAnalytics-ShareImage) {
  .widgetCardHeader {
    .title {
      > h3 {
        white-space: normal;
      }

      .icon {
        display: none;
      }
    }

    button {
      display: none;
    }
  }
}

@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.addMembers {
  height: 100%;
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;
  gap: 24px;
  box-sizing: border-box;

  .scrollable {
    padding-right: 16px;
  }

  .btn {
    padding-right: 16px;
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .addMembers {
    .scrollable {
      padding-right: 36px;
    }

    .btn {
      padding-right: 36px;
    }
  }
}

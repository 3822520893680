@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '../../../../Mixins/AuthCommon.module.scss';

.registrationVerifyView {
  @include AuthView;

  p {
    margin: 0;
  }

  .msgEmail {
    color: $sf-grey-600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;

    span {
      color: $sf-text-black;
      font-weight: 500;
    }
  }
}

:global(.nightMode) {
  .registrationVerifyView {
    .msgEmail {
      color: $sf-grey-400;

      span {
        color: $sf-text-white;
      }
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.rankingCardElementList {
  padding-left: 12px;
  padding-right: 12px;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .detail {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: $sf-grey-600;
  }

  .tooltipRef {
    width: fit-content;
  }
}

:global(.nightMode) {
  .rankingCardElementList {
    .detail {
      color: $sf-grey-400;
    }
  }
}

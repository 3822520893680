@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.businessCardSection {
  height: 100%;

  .scrollable {
    display: flex;
    flex-direction: column;
    padding: 0 16px 16px;
    box-sizing: border-box;

    .buttons {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 12px 0 20px;
    }

    .businessCard {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 24px 0 0;

      .footerText {
        padding: 24px 0 0;
        text-align: center;
      }
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .businessCardSection {
    .scrollable {
      .buttons {
        flex-direction: row;
        justify-content: flex-end;
        gap: 24px;
      }
    }
  }
}

@media screen and (min-width: $sf-media-md-width) {
  .businessCardSection {
    .scrollable {
      padding: 0 24px 24px;
    }
  }
}

@media screen and (min-width: $sf-media-lg-width) {
  .businessCardSection {
    .scrollable {
      padding: 0 48px 48px;
    }
  }
}

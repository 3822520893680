@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';
@import '../../Mixins/DialogCommon.module.scss';

@mixin contentWidth {
  width: 343px;

  @media screen and (min-width: $sf-media-sm-width) {
    width: 534px;
  }

  @media screen and (min-width: $sf-media-md-width) {
    width: 476px;
  }

  @media screen and (min-width: $sf-media-lg-width) {
    width: 394px;
  }

  @media screen and (min-width: $sf-media-xl-width) {
    width: 534px;
  }

  @media screen and (min-width: $sf-media-xxl-width) {
    width: 608px;
  }
}

@mixin imageHeight {
  height: 295px;

  @media screen and (min-width: $sf-media-sm-width) {
    height: 300px;
  }
}

.softwareUpdatedModal {
  @include responsiveDialog();

  .content {
    @include contentWidth();

    padding: 0;
    .image {
      @include imageHeight();
      border-bottom: 1px solid $sf-grey-100;
      object-fit: cover;
    }

    .text {
      margin-top: 12px;
      padding: 0 36px;

      .title {
        font-weight: 500;
        margin-bottom: 3px;
      }

      .subTitle {
        color: $sf-grey-600;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .updateButton {
      padding: 0 36px 36px;
      display: flex;
      flex-direction: column;

      @media screen and (min-width: $sf-media-sm-width) {
        > button {
          width: fit-content;
          align-self: flex-end;
        }
      }
    }
  }
}

:global(.nightMode) {
  .content {
    .image {
      border-bottom: 1px solid $sf-grey-700;
    }

    .text {
      .subTitle {
        color: $sf-grey-400;
      }
    }
  }
}

@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';
@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.sectionField {
  margin-left: 32px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .label {
    margin: 0;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-left: 12px;
  }

  .itemList {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .itemListContent {
      padding-left: 12px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .separator {
        border-bottom: 1px solid $sf-grey-100;
      }
    }
  }

  &.isSubSection {
    padding-bottom: 12px;
    border-bottom: 1px solid $sf-grey-100;
    margin-left: 12px;

    .label {
      margin: 0;
      font-weight: 500;
    }

    .container {
      margin-left: 0;
    }

    &:nth-last-child(1) {
      border: none;
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .sectionField {
    display: grid;
    grid-template-columns: 140px 1fr;
    gap: 32px;

    .container {
      margin-left: 0;
    }

    &.isSubSection {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-left: 0;
    }
  }
}

:global(.nightMode) {
  .sectionField {
    .itemList {
      .itemListContent {
        .separator {
          border-bottom: 1px solid $sf-grey-700;
        }
      }
    }
    &.isSubSection {
      border-bottom-color: $sf-grey-700;
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.linksBar {
  display: flex;
  list-style-type: none;
  gap: 12px;
  margin: 0;
  padding: 0;

  li {
    padding: 8px 22px;
    button {
      text-transform: none;
    }
  }
}

:global(.nightMode) {
  .linksBar {
    li {
      a[class*='MuiTypography-colorInherit'] {
        color: $sf-grey-200;
      }
    }
  }
}

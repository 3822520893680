@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.footer {
  padding: 0 36px;
}

@media screen and (min-width: $sf-media-sm-width) {
  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

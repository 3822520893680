@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.divider {
  width: 100%;
  margin: 0;
  border: 0;
  border-bottom-style: solid;
  border-bottom-color: $sf-grey-100;
}

:global(.nightMode) {
  .divider {
    border-bottom-color: $sf-grey-700;
  }
}

@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';
@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.advancedFiltersMenu {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 36px;
  padding-right: 16px;

  > button {
    margin-right: auto;
  }

  .navMenu {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 0;
    padding: 0;
    list-style-type: none;

    > li {
      display: flex;
      align-items: center;
      gap: 6px;
    }

    .menuItem {
      @media (hover: hover) {
        &:hover {
          cursor: pointer;
          font-weight: 500;
          color: $sf-grey-900;
        }
      }

      &.isSelected,
      &:active {
        cursor: pointer;
        font-weight: 700;
        color: $sf-grey-900;
      }
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .advancedFiltersMenu {
    padding-right: 36px;
  }
}

@media screen and (min-width: $sf-media-md-width) {
  .advancedFiltersMenu {
    gap: 24px;

    > button {
      margin-right: unset;
    }
  }
}

:global(.nightMode) {
  .advancedFiltersMenu {
    .navMenu {
      .menuItem {
        @media (hover: hover) {
          &:hover {
            cursor: pointer;
            color: $sf-grey-50;
          }
        }

        &.isSelected,
        &:active {
          cursor: pointer;
          color: $sf-grey-50;
        }
      }
    }
  }
}

@mixin LandingComponent {
  padding: 0 16px;

  @media screen and (min-width: $sf-media-sm-width) {
    padding-left: 118px;
    padding-right: 118px;
  }

  @media screen and (min-width: $sf-media-md-width) {
    padding-left: 190px;
    padding-right: 180px;
  }

  @media screen and (min-width: $sf-media-lg-width) {
    padding-left: 234px;
    padding-right: 234px;
  }
  @media screen and (min-width: $sf-media-xl-width) {
    padding-left: 288px;
    padding-right: 288px;
  }
  @media screen and (min-width: $sf-media-xxl-width) {
    padding-left: 500px;
    padding-right: 500px;
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

@mixin errorView($lightTheme: true) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 24px;
  padding: 16px;

  @if $lightTheme {
    background-color: $sf-background-light;
  } @else {
    background-color: $sf-background-dark;
  }

  .icon {
    svg {
      path {
        @if $lightTheme {
          fill: $sf-blue-500 !important;
        } @else {
          fill: $sf-blue-200 !important;
        }
      }
    }
  }

  .title {
    margin: 0;
    text-align: center;
  }

  .subText {
    margin: 0;
    text-align: center;
    line-height: 24px;
    font-size: 16px;
  }
}

@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.saveObjectDrawer {
  [class*='MuiDrawer-paper'] {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 50px 1fr 66px;
    grid-template-columns: 100%;
    transition-timing-function: cubic-bezier(0.3, 0, 0.2, 1) !important;
  }

  .content {
    min-height: 0;
    padding: 0 16px 16px 16px;
    box-sizing: border-box;
  }

  footer {
    padding: 0 16px 16px 16px;
  }
}

@media screen and (min-width: $sf-media-xs-width+1) {
  .saveObjectDrawer {
    [class*='MuiDrawer-paper'] {
      width: inherit;
    }
  }
}

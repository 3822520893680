@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';
@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.fieldItemList {
  margin-left: 32px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .title {
    margin: 0;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-left: 12px;
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .fieldItemList {
    display: grid;
    grid-template-columns: 140px 1fr;
    gap: 32px;

    .container {
      margin-left: 0;
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFTypography/SFTypography.module.scss';

.field {
  .fieldLabel {
    color: $sf-grey-600;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
  }

  .options {
    display: grid;
    margin: 6px 0 0 0;
  }

  .helperText {
    @include component-messages;
    margin: 0;
    padding: 12px 0 0 12px;
    color: rgba(0, 0, 0, 0.54);

    &.error {
      color: $sf-red-main-light;
    }
  }
}

:global(.nightMode) {
  .field {
    .fieldLabel {
      color: $sf-grey-400;
    }

    .helperText {
      color: rgba(255, 255, 255, 0.7);

      &.error {
        color: $sf-red-main-dark;
      }
    }
  }
}

@import '~sfui/dist/Styles/SFTypography/SFTypography.module.scss';
@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.analyticsActivate {
  padding: 48px 16px 0;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .video {
    width: 100%;
  }

  .title {
    margin: 0;
    padding-top: 24px;
    padding-bottom: 12px;
  }

  .description {
    margin: 0;
    padding-bottom: 36px;
    color: $sf-grey-600;
    @include component-2;
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .analyticsActivate {
    padding-top: 144px;

    .video {
      width: 534px;
    }
  }
}

@media screen and (min-width: $sf-media-md-width) {
  .analyticsActivate {
    padding-top: 48px;

    .video {
      width: 480px;
    }
  }
}

@media screen and (min-width: $sf-media-lg-width) {
  .analyticsActivate {
    padding-top: 24px;
    .video {
      width: 604px;
    }
  }
}

@media screen and (min-width: $sf-media-xl-width) {
  .analyticsActivate {
    padding-top: 48px;
    .video {
      width: 766px;
    }
  }
}

@media screen and (min-width: $sf-media-xxl-width) {
  .analyticsActivate {
    padding-top: 96px;
    .video {
      width: 924px;
    }
  }
}

:global(.nightMode) {
  .analyticsActivate {
    .description {
      color: $sf-grey-400;
    }
  }
}

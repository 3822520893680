@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.tinyWidgetsContent {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 12px 0;

  .horizontalDivider {
    grid-column: span 2;
  }

  .widget {
    border-right: 1px solid $sf-grey-100;

    &.noBorder {
      border-right: none;
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .tinyWidgetsContent {
    grid-template-columns: repeat(3, minmax(0, 1fr));

    .horizontalDivider {
      grid-column: span 3;
    }

    .widget {
      border-right: 1px solid $sf-grey-100;
    }
  }
}

@media screen and (min-width: $sf-media-md-width) {
  .tinyWidgetsContent {
    grid-template-columns: repeat(6, 1fr);

    .widget {
      &:nth-child(3) {
        border-right: 1px solid $sf-grey-100;
      }
    }
  }
}

:global(.nightMode) {
  .tinyWidgetsContent {
    .widget {
      border-color: $sf-grey-700;
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.reportHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 0 36px;

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;

    button {
      width: 100%;
    }

    .content {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;

      .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: $sf-surface-light;

        .badge {
          width: 62px;
          height: 62px;
          border-radius: 50%;
        }

        .abbreviation {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 62px;
          height: 62px;
          margin: 0;
          color: $sf-blue-400;
          text-transform: uppercase;
          font-weight: 700;
        }
      }

      .text {
        grid-area: text;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2px;

        h5 {
          margin: 0;
          font-size: 14px;
          line-height: 20px;
          font-weight: bold;
          text-transform: uppercase;
        }

        p {
          margin: 0;
          font-size: 10px;
          line-height: 12px;
          text-transform: uppercase;
        }
      }
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .reportHeader {
    .container {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      button {
        width: auto;
      }
    }
  }
}

.analyticsWidgetsLoader {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    transition: opacity 360ms ease-in-out;

    .messageContainer {
      height: 48px;
      width: 220px;
      position: relative;

      p {
        position: absolute;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: opacity 360ms ease-in-out;

        &.show {
          opacity: 1;
        }

        &.hide {
          opacity: 0;
        }
      }
    }
  }
}

@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.filterMap {
  position: relative;
  width: 100%;
  height: 100%;
  gap: 24px;
  display: grid;
  grid-template-columns: 1fr;

  .filterSelector {
    display: none;
  }
}

.drawerSave {
  padding-top: 24px;
}

:global(.SFAnalytics-ShareImage) {
  .filterMap {
    height: auto;
    display: flex;
    flex-direction: column;

    [class*='GoogleMap'] {
      height: 505px;
    }

    .filterSelector {
      display: block;
    }
  }
}

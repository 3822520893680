.filterCategoryForm {
  display: flex;
  flex-direction: column;
  gap: 36px;

  .section {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .subsection {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}

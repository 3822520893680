@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.container {
  height: 100vh;
  background-color: $sf-background-light;

  .main {
    height: 100%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 58px 1fr;
    grid-template-areas:
      'topbar'
      'main';

    .navPanel {
      display: none;
      grid-area: nav;
    }

    .topBar {
      grid-area: topbar;
    }

    .mainView {
      position: relative;
      grid-area: main;

      min-height: 0;

      &.fullWidth {
        max-width: 100%;
        margin: 0;
      }

      .alert {
        padding: 0 12px;
        box-sizing: border-box;
        width: 100%;
        position: absolute;
        z-index: 2;
      }
    }

    &.showBottomTitle {
      grid-template-rows: 116px 1fr;
    }
  }
}

:global(.nightMode) {
  .container {
    background-color: $sf-background-dark;
  }
}

:global(.isSafari) {
  .container {
    height: -webkit-fill-available;

    .loader {
      height: -webkit-fill-available;
    }

    .main {
      height: -webkit-fill-available;
    }
  }
}

@media screen and (min-width: ($sf-media-xs-width+1)) {
  .container {
    .main {
      .mainView {
        height: 100%;
      }
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .container {
    .main {
      grid-template-rows: 66px 1fr;

      .mainView {
        .alert {
          padding: 0 18px;
        }
      }

      &.showBottomTitle {
        grid-template-rows: 142px 1fr;
      }
    }
  }
}

@media screen and (min-width: $sf-media-lg-width) {
  .container {
    .main {
      grid-template-areas: 'nav topbar' 'nav main';
      grid-template-columns: auto 1fr;
      grid-template-rows: 88px minmax(0, 1fr);

      .mainView {
        .alert {
          padding: 0 36px;
        }
      }

      &.isMinimalMode {
        grid-template-columns: 0px 1fr;
      }

      .navPanel {
        display: block;
      }
    }
  }
}

@media print {
  .container {
    .main {
      display: block;
      grid-template-rows: auto;
      grid-template-columns: auto;

      .topBar,
      .navPanel {
        display: none;
      }
    }
  }
}

@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.scrollable {
  box-sizing: border-box;
}
.gradientLine {
  padding-bottom: 24px;
  box-sizing: border-box;

  .echart {
    height: 100%;
    max-width: 648px;
    width: 648px;

    & > div {
      cursor: default !important;
    }
  }

  .downloadFooter {
    display: none;
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .gradientLine {
    padding-bottom: 36px;

    .echart {
      max-width: 100%;
      width: 100%;
    }
  }
}

:global(.SFAnalytics-ShareImage) {
  .scrollable {
    height: auto;
    padding-bottom: 0;
  }

  .gradientLine {
    padding-bottom: 0;

    .downloadFooter {
      display: flex;
      padding: 14px 36px 0 36px;
      justify-content: flex-end;
    }
  }
}

:global(.gradientLineTooltip) {
  box-shadow: none !important;
  font-weight: 500;
  font-family: Roboto;
  font-size: 13px;
  line-height: 22px;
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.landingHighlightItem {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  color: $sf-surface-light;

  .text {
    text-align: center;

    > h1 {
      margin: 0;
      padding-bottom: 10px;
      font-weight: 700;
      font-size: 40px;
      line-height: 30px;
    }

    > h2 {
      margin: 0;
      font-weight: 300;
      font-size: 22px;
      line-height: 30px;
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .landingHighlightItem {
    gap: 38px;
  }
}

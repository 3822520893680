.durationForm {
  .durationLabel {
    display: flex;
    align-items: center;
    gap: 10px;

    > h3 {
      font-weight: 500;
    }
  }
}

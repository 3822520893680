.tagsFieldOption {
  display: flex;
  gap: 15px;
  align-items: center;

  .color {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.masonryElement {
  grid-column: span 1;

  &.xs {
    height: 217px;
  }

  &.sm,
  &.md,
  &.lg {
    height: 643px;
  }

  @media screen and (min-width: $sf-media-sm-width) {
    &.md,
    &.lg {
      grid-column: span 2;
    }
  }

  @media screen and (min-width: $sf-media-lg-width) {
    &.lg {
      &.isNavPanelCollapsed {
        grid-column: span 3;
      }
    }
  }

  @media screen and (min-width: $sf-media-xl-width) {
    &.lg {
      grid-column: span 3;
    }
  }

  box-sizing: border-box;
  border: 2px dashed transparent;

  .dragContainer {
    height: 100%;
    width: 100%;
  }

  &.isDragging {
    background-color: transparent;
    border-radius: 2px;

    .dragContainer {
      transition: 0.01s;
      transform: translateX(-9999px);
      box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.02),
        0px 12px 17px 2px rgba(0, 0, 0, 0.14),
        0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    }
  }

  &.isDragEnter {
    &:not(.isDragging) {
      border-color: $sf-blue-main-light;
      border-radius: 2px;
    }
  }
}

@import '../../Mixins/ErrorCommon.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.notMemberView {
  height: 100%;
  @include errorView;
}

:global(.isSafari) {
  .notMemberView {
    height: -webkit-fill-available;
  }
}

:global(.nightMode) {
  .notMemberView {
    @include errorView(false);
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .notMemberView {
    .subText {
      span {
        display: block;
      }
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.sectionFieldItem {
  display: flex;
  flex-direction: column;
  gap: 2px;

  .label {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $sf-grey-600;
  }

  .value {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $sf-grey-900;
  }
}

:global(.nightMode) {
  .sectionFieldItem {
    .label {
      color: $sf-grey-400;
    }

    .value {
      color: $sf-grey-50;
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .sectionFieldItem {
    margin: 0;

    &:first-child {
      padding-top: 2px;
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFTypography/SFTypography.module.scss';

.patterMapItemIncident {
  padding: 6px 12px;
  display: flex;
  gap: 9px;

  &.isClickable {
    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        background-color: rgba(204, 204, 204, 0.3);
      }
    }

    &:active {
      background-color: rgba(204, 204, 204, 0.5);
    }
  }

  .itemIndex {
    @include component-1;
    border-right: 2px solid $sf-blue-500;
    color: $sf-grey-900;
    display: flex;
    gap: 6px;
    padding-right: 6px;
    box-sizing: border-box;
  }

  .incident {
    display: flex;
    flex-direction: column;
    gap: 3px;
    flex: 1;

    .title {
      @include component-messages;
      color: $sf-grey-600;
    }

    .info {
      display: flex;

      .incidentNumber {
        @include component-1-medium;
        color: $sf-grey-900;
        flex: 1;
      }

      .incidentDate {
        @include component-1;
        color: $sf-grey-900;
      }
    }
  }
}

:global(.nightMode) {
  .patterMapItemIncident {
    &.isClickable {
      @media (hover: hover) {
        &:hover {
          background-color: rgba(128, 128, 128, 0.3);
        }
      }

      &:active {
        background-color: rgba(128, 128, 128, 0.5);
      }
    }

    .itemIndex {
      border-color: $sf-blue-200;
      color: $sf-grey-50;
    }

    .incident {
      .title {
        color: $sf-grey-400;
      }

      .info {
        .incidentNumber {
          color: $sf-grey-50;
        }

        .incidentDate {
          color: $sf-grey-50;
        }
      }
    }
  }
}

@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';
@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.setLocationModal {
  div.dialogPaper {
    box-sizing: border-box;
    margin: 24px;
    height: 100%;
    width: 100%;
    max-width: 100%;
  }

  div.dialogContainer {
    height: 100%;
  }

  .container {
    height: 100%;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    gap: 24px;

    .header {
      display: flex;
      flex-direction: column;
      gap: 3px;
    }

    .form {
      display: grid;
      grid-template-rows: minmax(0, 1fr) auto;
      gap: 12px;

      .map {
        position: relative;

        .marker {
          z-index: 2;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-left: -22px;
          margin-top: -46px;
        }
      }
    }
  }
}

@media screen and (min-width: $sf-media-md-width) {
  .setLocationModal {
    div.dialogPaper {
      width: 570px;
    }
  }
}
@media screen and (min-width: $sf-media-lg-width) {
  .setLocationModal {
    div.dialogPaper {
      width: 532px;
    }
  }
}

@media screen and (min-width: $sf-media-xl-width) {
  .setLocationModal {
    div.dialogPaper {
      width: 692px;
    }
  }
}

@media screen and (min-width: $sf-media-xxl-width) {
  .setLocationModal {
    div.dialogPaper {
      width: 852px;
    }
  }
}

:global(.nightMode) {
  .setLocationModal {
    .container {
      .form {
        .marker path {
          fill: $sf-blue-main-dark !important;
        }
      }
    }
  }
}

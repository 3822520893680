.numberRangeField {
  display: flex;
  flex-direction: column;
  gap: 6px;

  .fieldContainer {
    display: flex;
    gap: 12px;

    .field {
      max-width: 166px;
    }
  }
}

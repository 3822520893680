@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.tagsInformation {
  display: flex;
  flex-direction: column;
  gap: 24px;

  h3,
  h4 {
    margin: 0;
  }

  .tags {
    margin-left: 32px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .chips {
      display: flex;
      gap: 3px;
      flex-wrap: wrap;
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .tagsInformation {
    .tags {
      display: grid;
      grid-template-columns: 140px 1fr;
      gap: 32px;
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.noData {
  width: 100%;
  height: 100%;
  padding: 12px;
  box-sizing: border-box;
  background-color: $sf-background-light;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .image {
    margin-bottom: 12px;

    > svg {
      filter: grayscale(100%);
    }
  }

  > h1,
  > h2 {
    margin: 0;
    color: $sf-grey-600;
  }

  .message {
    text-align: center;

    > a {
      font-weight: 500;
      text-decoration: none;
      color: $sf-blue-main-light;
    }
  }

  &.isRateCard {
    align-items: flex-start;

    .message {
      text-align: left;
    }
  }
}

:global(.nightMode) {
  .noData {
    background-color: $sf-background-dark;

    > h1,
    h2 {
      color: $sf-grey-400;
    }

    .message {
      > a {
        color: $sf-blue-main-dark;
      }
    }
  }
}

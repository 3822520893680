@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.landingTopBar {
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  background-color: $sf-background-light;
  box-sizing: border-box;

  .brandIcon {
    padding: 6px;
    border-radius: 4px;
  }

  .navigation {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  .brandButton {
    width: 42px;
    height: 42px;
    background-color: transparent;
    border-radius: 10px;
    cursor: pointer;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    display: inline-flex;
    align-items: center;
    user-select: none;
    justify-content: center;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;

    > img {
      width: 28px;
      height: 28px;
    }

    @media (hover: hover) {
      &:hover {
        background-color: rgba(204, 204, 204, 0.4);
      }
    }

    &:active {
      background-color: rgba(204, 204, 204, 0.6);
    }
  }

  @media screen and (min-width: $sf-media-sm-width) {
    padding: 8px 117px;
  }

  @media screen and (min-width: $sf-media-md-width) {
    padding: 8px 191px;
  }

  @media screen and (min-width: $sf-media-lg-width) {
    padding: 8px 233px;
  }

  @media screen and (min-width: $sf-media-xl-width) {
    padding: 8px 287px;
  }

  @media screen and (min-width: $sf-media-xxl-width) {
    padding: 8px 498px;
  }
}

:global(.nightMode) {
  .landingTopBar {
    background-color: $sf-background-dark;

    .brandIcon {
      background-color: $sf-background-light;
    }
  }
}

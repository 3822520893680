@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.tinyWidgets {
  background-color: $sf-surface-light;

  .container {
    display: flex;
    flex-direction: column;
    padding: 12px;
    border-radius: 2px;
    border: 1px solid $sf-grey-100;
  }

  .collapseButton {
    display: flex;
    height: 50px;
    width: 100%;
    padding: 12px 96px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border: 2px solid $sf-grey-100;
    border-top: none;
    border-radius: 0 0 12px 12px;
  }
}

:global(.nightMode) {
  .tinyWidgets {
    background-color: $sf-surface-dark;

    .container {
      border-color: $sf-grey-700;
    }

    .collapseButton {
      border-color: $sf-grey-700;
    }
  }
}

.filterSelectorItem {
  display: flex;
  align-items: center;
  gap: 12px;

  .circleColor {
    border-radius: 50%;
    width: 10px;
    height: 10px;
  }

  .label {
    flex: 1;
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';
@import '../LandingCommon.module.scss';

.landingContact {
  @include LandingComponent;
  padding-top: 90px;
  padding-bottom: 90px;

  .title {
    margin: 0;
    padding-bottom: 24px;
    text-align: center;
    color: $sf-blue-800;
    font-size: 36px;
    line-height: 48px;
    font-weight: 700;
    letter-spacing: -0.02em;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .info {
      display: flex;
      flex-direction: column;
      gap: 6px;

      > h2 {
        margin: 0;
        color: $sf-grey-900;
        font-size: 20px;
        line-height: 37px;
        font-weight: 700;
        letter-spacing: -0.02em;
      }

      .link {
        color: $sf-blue-500;
        text-decoration: none;
        font-size: 18px;
        line-height: 37px;
        font-weight: 500;
        letter-spacing: -0.02em;

        @media (hover: hover) {
          &:hover {
            text-decoration: underline;
          }
        }

        &:active {
          text-decoration: underline;
        }
      }

      .address {
        margin: 0;
        font-size: 18px;
        line-height: 40px;
        font-weight: 400;
        letter-spacing: -0.02em;
      }
    }

    .image > img {
      width: 100%;
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .landingContact {
    padding-bottom: 90px;
  }
}

@media screen and (min-width: $sf-media-md-width) {
  .landingContact {
    .content {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      direction: rtl;
      justify-content: center;

      .info {
        direction: ltr;
      }
    }
  }
}

:global(.nightMode) {
  .landingContact {
    .title {
      color: $sf-blue-100;
    }

    .content {
      .info {
        > h2 {
          color: $sf-grey-200;
        }

        .link {
          color: $sf-blue-200;
        }

        .address {
          color: $sf-grey-400;
        }
      }
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.landingFeatureList {
  .title {
    margin: 0;
    padding-bottom: 24px;
    color: $sf-blue-800;
    font-size: 22px;
    line-height: 26px;
    font-weight: 700;
    letter-spacing: -0.01em;
  }

  > hr {
    margin: 0;
    width: 133px;
    border: 0;
    border-top: 2px solid $sf-blue-800;
  }

  ul.list {
    margin: 0;
    padding-top: 24px;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;

    li {
      color: $sf-grey-800;
      font-size: 18px;
      line-height: 24px;
    }
  }
}

:global(.nightMode) {
  .landingFeatureList {
    .title {
      color: $sf-blue-200;
    }

    > hr {
      border-top: 2px solid $sf-blue-200;
    }

    ul.list {
      li {
        color: $sf-grey-400;
      }
    }
  }
}

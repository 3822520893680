.contactForm {
  h3 {
    font-weight: 500;
    margin: 0 0 24px 0;
  }

  h4 {
    margin: 0 0 12px 0;
    font-weight: 500;
  }

  .generalInformation {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .inputField {
      display: flex;
      flex-direction: column;
      gap: 12px;

      h4 {
        margin: 0;
      }
    }

    .defaultFields {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFTypography/SFTypography.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';

.linksPanel {
  width: 285px;
  display: grid;
  grid-template-rows: auto 1fr;

  .header {
    display: flex;
    justify-content: flex-end;
    padding: 8px 12px;
    box-sizing: border-box;
  }

  .links {
    @include component-1;
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 6px;

    .link {
      cursor: pointer;
      display: flex;
      gap: 18px;
      box-sizing: border-box;
      text-decoration: none;
      font-weight: 500;

      .text {
        margin: 0;
        padding: 12px 20px 12px 12px;
      }

      .pointer {
        height: 100%;
        border-radius: 0 2px 2px 0;
      }

      @media (hover: hover) {
        &:hover {
          background-color: rgba($sf-grey-a100, 0.5);
        }
      }

      &:active {
        background-color: rgba($sf-grey-500, 0.5);
      }

      &.selected {
        .text {
          padding-left: 6px;
          color: $sf-blue-500;
        }

        .pointer {
          background-color: $sf-blue-500;
          width: 6px;
        }
      }
    }
  }

  @media screen and (min-width: $sf-media-sm-width) {
    width: 396px;
  }
}

:global(.nightMode) {
  .linksPanel {
    .links {
      .link {
        color: $sf-grey-200;

        @media (hover: hover) {
          &:hover {
            background-color: rgba($sf-grey-500, 0.3);
          }
        }

        &:active {
          background-color: rgba($sf-grey-500, 0.5);
        }

        &.selected {
          .text {
            color: $sf-blue-200;
          }

          .pointer {
            background-color: $sf-blue-200;
          }
        }
      }
    }
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';
@import '../../Constants/Colors.module.scss';

.pricingView {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 58px minmax(0, 1fr);
  overflow-x: hidden;

  p,
  h1,
  h2 {
    margin: 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 36px 16px 60px 16px;
    gap: 48px;
    background: url('../../Images/Landing/DM_pricing_background.png') no-repeat
      bottom;
    background-size: 2724px 1258px;
    background-position: bottom -120px center;
    overflow-x: hidden;

    .pricing {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .cycleOptions {
        display: flex;
        gap: 48px;
      }

      .cards {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
    }

    .textBlock {
      display: flex;
      flex-direction: column;
      gap: 6px;

      .title {
        font-weight: 700;
        font-size: 36px;
        line-height: 48px;
        color: $cc-purple-text;
        margin-bottom: 6px;
      }

      .subTitle {
        color: $cc-purple-text;
      }

      .description {
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 24px;
      }
    }

    .helpBlock {
      display: flex;
      flex-direction: column;
      gap: 6px;

      .link {
        font-size: 18px;
        line-height: 36px;
        text-align: center;
      }

      .help {
        font-size: 18px;
        line-height: 26px;
        text-align: center;
      }
    }
  }

  @media screen and (min-width: $sf-media-sm-width) {
    .content {
      padding: 36px 116px 100px;
      background-size: 2724px 1224px;
      background-position: bottom;

      .helpBlock {
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }
  }

  @media screen and (min-width: $sf-media-md-width) {
    .content {
      padding-left: 192px;
      padding-right: 192px;
      background-size: 1920px 864px;
      background-position: bottom -45px center;

      .pricing {
        padding: 0 46px;
      }
    }
  }

  @media screen and (min-width: $sf-media-lg-width) {
    .content {
      padding-left: 232px;
      padding-right: 232px;
      background-position: bottom;

      .pricing {
        padding: 0;

        .cards {
          display: grid;
          grid-template-columns: repeat(2, minmax(255px, 1fr));
        }
      }
    }
  }

  @media screen and (min-width: $sf-media-xl-width) {
    .content {
      padding-left: 288px;
      padding-right: 288px;
    }
  }

  @media screen and (min-width: $sf-media-xxl-width) {
    .content {
      padding-left: 498px;
      padding-right: 498px;
      background-size: cover;
    }
  }
}

:global(.nightMode) {
  .pricingView {
    background-color: $sf-background-dark;

    .content {
      background-image: url('../../Images/Landing/NM_pricing_background.png');
      .textBlock {
        .title {
          color: $sf-blue-100;
        }

        .subTitle {
          color: $sf-blue-100;
        }

        .description {
          color: $sf-grey-200;
        }
      }

      .helpBlock {
        .help {
          color: $sf-grey-200;
        }
      }
    }
  }
}

:global(.isSafari) {
  .pricingView {
    height: -webkit-fill-available;
  }
}

.wizardCardStepContainer {
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  &.withHeader {
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    grid-template-columns: 100%;
  }
}

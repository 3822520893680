@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';
@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.reportSubmittedView {
  &.isSubmitted {
    padding-top: 100px;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;

    .alert {
      margin: 16px 16px 24px 16px;

      .icon {
        path {
          fill: $sf-blue-900 !important;
        }
      }
    }

    .qrCode {
      margin: 0 auto;

      .svgImage {
        cursor: pointer;
      }
    }

    .title {
      text-align: center;
      margin: 24px 0 12px;
    }

    .subTitle {
      text-align: center;
      font-size: 14px;
      line-height: 20px;
      margin: 0;
    }

    .footer {
      margin-top: 36px;
      align-self: center;
    }
  }
}

:global(.nightMode) {
  .reportSubmittedView {
    .container {
      .alert {
        .icon {
          path {
            fill: $sf-blue-50 !important;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .reportSubmittedView {
    .container {
      .alert {
        width: 534px;
        margin-top: 100px;
      }

      .qrCode {
        .svgImage {
          width: 348px;
          height: 348px;
        }
      }
    }
  }
}

@media screen and (min-width: $sf-media-md-width) {
  .reportSubmittedView {
    .container {
      .alert {
        width: 790px;
        margin-top: 48px;
      }

      .qrCode {
        .svgImage {
          width: 310px;
          height: 310px;
        }
      }
    }
  }
}

@media screen and (min-width: $sf-media-lg-width) {
  .reportSubmittedView {
    padding-top: 48px;

    .container {
      .alert {
        width: 603px;
        margin-top: 0px;
      }

      .qrCode {
        .svgImage {
          width: 185px;
          height: 185px;
        }
      }
    }
  }
}

@media screen and (min-width: $sf-media-xl-width) {
  .reportSubmittedView {
    .container {
      .alert {
        width: 501px;
      }

      .qrCode {
        .svgImage {
          width: 240px;
          height: 240px;
        }
      }
    }
  }
}

@media screen and (min-width: $sf-media-xxl-width) {
  .reportSubmittedView {
    .container {
      .alert {
        width: 608px;
      }

      .qrCode {
        .svgImage {
          width: 292px;
          height: 292px;
        }
      }
    }
  }
}

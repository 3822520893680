@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';
@import '~sfui/dist/Styles/SFMedia/SFMedia.module.scss';
@import '../LandingCommon.module.scss';

.landingFeatures {
  @include LandingComponent;
  padding-top: 72px;
  padding-bottom: 72px;

  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: #f6f6f6;
  padding: 48px 16px;

  .features {
    display: flex;
    flex-direction: column;
    flex-basis: 0;
    flex-grow: 1;
    gap: 48px;
  }
}

@media screen and (min-width: $sf-media-sm-width) {
  .landingFeatures {
    .features {
      gap: 60px;
    }
  }
}

@media screen and (min-width: $sf-media-lg-width) {
  .landingFeatures {
    .features {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}

@media screen and (min-width: $sf-media-xl-width) {
  .landingFeatures {
    padding-top: 84px;
    padding-bottom: 84px;
  }
}

:global(.nightMode) {
  .landingFeatures {
    background-color: $sf-surface-dark;
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

.smartforceLegend {
  width: fit-content;
  padding: 10px;
  box-sizing: border-box;
  color: $sf-grey-600;
  background-color: $sf-background-light;
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;

  .strong {
    font-weight: 700;
  }
}

:global(.nightMode) {
  .smartforceLegend {
    color: $sf-grey-50;
    background-color: $sf-surface-dark;
  }
}

@import '~sfui/dist/Styles/SFColors/SFColors.module.scss';

@mixin onboardingView($lightTheme: true) {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  .icon {
    svg {
      path {
        @if $lightTheme {
          fill: $sf-blue-500 !important;
        } @else {
          fill: $sf-blue-200 !important;
        }
      }
    }
  }

  .title {
    margin: 24px 0 0 0;
  }

  .subTitle {
    margin: 12px 0 0 0;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0px;
    @if $lightTheme {
      color: $sf-grey-600;
    } @else {
      color: $sf-grey-400;
    }
  }

  .btnAction {
    margin-top: 36px;
  }
}

.noCoordinatesBtn {
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}

:global(.SFAnalytics-ShareImage) {
  .noCoordinatesBtn {
    display: none;
  }
}
